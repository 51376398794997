.perk-slot {
  height: 98px;
  width: 189px;
}

.perk-slot .rectangle-9 {
  position: relative;
}

.perk-slot .rectangle-wrapper {
  background-color: #dba415;
  height: 99px;
  position: relative;
  top: 6px;
}

.perk-slot .rectangle-10 {
  background-color: #ffe382;
  height: 99px;
  left: 3px;
  position: relative;
  width: 3px;
}

.perk-slot .frame-37 {
  height: 112px;
  left: 0;
  position: absolute;
  top: 0;
  width: 203px;
}

.perk-slot .overlap-group-13 {
  height: 112px;
  position: relative;
}

.perk-slot .v-e {
  height: 12px;
  left: 191px;
  position: absolute;
  top: 100px;
  width: 12px;
}

.perk-slot .vf {
  height: 12px;
  left: 0;
  position: absolute;
  top: 100px;
  width: 12px;
}

.perk-slot .v {
  height: 12px;
  left: 191px;
  position: absolute;
  top: 0;
  width: 12px;
}

.perk-slot .v-2 {
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}

.perk-slot .rectangle-11 {
  background-color: #dba415;
  height: 7px;
  left: 12px;
  position: absolute;
  top: 0;
  width: 179px;
}

.perk-slot .rectangle-12 {
  background-color: #dba415;
  height: 7px;
  left: -41px;
  position: absolute;
  top: 53px;
  transform: rotate(90deg);
  width: 88px;
}

.perk-slot .rectangle-13 {
  background-color: #dba415;
  height: 7px;
  left: 155px;
  position: absolute;
  top: 53px;
  transform: rotate(90deg);
  width: 88px;
}

.perk-slot .rectangle-14 {
  background-color: #dba415;
  height: 7px;
  left: 12px;
  position: absolute;
  top: 105px;
  width: 179px;
}

.perk-slot .frame-ec {
  height: 106px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 197px;
}

.perk-slot .bg-2 {
  border: 1px solid;
  border-radius: 3px;
  height: 98px;
  opacity: 0.7;
  position: absolute;
  width: 189px;
}

.perk-slot .v-fb-a-d {
  height: 67px;
  left: 4px;
  position: absolute;
  top: 27px;
  width: 181px;
}

.perk-slot .vector-wrapper {
  background-image: url(../../../static/anima/img/ve-8-1c-7-0e-3.svg);
  background-size: 100% 100%;
  height: 67px;
  left: 4px;
  position: absolute;
  top: 27px;
  width: 181px;
}

.perk-slot .vector-8 {
  height: 61px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 174px;
}

.perk-slot .rectangle-15 {
  height: 6px;
  left: 4px;
  opacity: 0.4;
  position: absolute;
  top: 10px;
  width: 181px;
}

.perk-slot .PT-perkname {
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 13px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: 183px;
}

.perk-slot .image-perk {
  align-items: center;
  display: inline-flex;

  height: 77px;
  justify-content: center;
  left: 12px;
  padding: 0px 44px;
  position: absolute;
  top: 30px;
}

.perk-slot .perkimg-sample-2 {
  height: 92px;
  margin-bottom: -7.5px;
  margin-top: -7.5px;
  object-fit: cover;
  position: relative;
  width: 92px;
}

.perk-slot .text-wrapper-6 {
  color: #00000003;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 98px;
  left: 7px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 6px;
  width: 188px;
}

.perk-slot .bg-3 {
  background-color: #d4c590;
  border-radius: 3px;
  height: 98px;
  left: 4px;
  opacity: 0.3;
  position: absolute;
  top: 4px;
  width: 189px;
}

.perk-slot .PT-perkname-bold {
  color: #673d0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 13px;
  left: 7px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 6px;
  white-space: nowrap;
  width: 183px;
}

.perk-slot .f-f-a {
  height: 106px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 197px;
}

.perk-slot .perkimg-wrapper {
  align-items: center;
  display: flex;

  height: 77px;
  justify-content: center;
  left: 9px;
  padding: 0px 44px;
  position: absolute;
  top: 27px;
  width: 180px;
}

.perk-slot .text-wrapper-7 {
  color: #00000003;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 98px;
  left: 4px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 3px;
  width: 188px;
}

.perk-slot .img-wrapper {
  align-items: center;
  display: inline-flex;

  height: 77px;
  justify-content: center;
  left: 9px;
  padding: 0px 44px;
  position: absolute;
  top: 27px;
}

.perk-slot .vb {
  height: 37px;
  left: 0;
  position: absolute;
  top: 69px;
  width: 27px;
}

.perk-slot .state-branch-b {
  background-color: #dba415;
  height: 98px;
  left: 93px;
  width: 3px;
}

.perk-slot .state-branch-a {
  height: 112px;
  left: 90px;
  top: -7px;
  width: 9px;
}

.perk-slot .state-locked {
  height: 106px;
  left: -4px;
  top: -4px;
  width: 197px;
}

.perk-slot .state-completed {
  height: 112px;
  left: -7px;
  top: -7px;
  width: 203px;
}

.perk-slot .state-unlocked {
  height: 106px;
  left: -4px;
  top: -4px;
  width: 197px;
}

.perk-slot .state-branch-x {
  height: 112px;
  left: -4px;
  width: 197px;
}

.perk-slot .rectangle-9.state-branch-b .bg-2 {
  background-color: #ffc600;
  border-color: #c68300;
  box-shadow: 0px 0px 3px 1px #ce7b00;
  left: 4px;
  top: 4px;
}

.perk-slot .rectangle-9.state-branch-a .bg-2 {
  background-color: #ffc600;
  border-color: #c68300;
  box-shadow: 0px 0px 3px 1px #ce7b00;
  left: 4px;
  top: 4px;
}

.perk-slot .rectangle-9.state-locked .bg-2 {
  background-color: #aa8a3a;
  border-color: #a27c32;
  box-shadow: 0px 0px 3px 1px #ce7b00;
  left: 4px;
  top: 4px;
}

.perk-slot .rectangle-9.state-completed .bg-2 {
  background-color: #ffc600;
  border-color: #c68300;
  box-shadow: 0px 0px 3px 1px #ce7b00;
  left: 7px;
  top: 7px;
}

.perk-slot .rectangle-9.state-unlocked .bg-2 {
  background-color: #f9dc73;
  border-color: #c68f00;
  box-shadow: 0px 0px 3px 1px #977a2f;
  left: 4px;
  top: 4px;
}

.perk-slot .rectangle-9.state-branch-x .bg-2 {
  background-color: #ffc600;
  border-color: #c68300;
  box-shadow: 0px 0px 3px 1px #ce7b00;
  left: 4px;
  top: 4px;
}

.perk-slot .rectangle-9.state-branch-b .rectangle-15 {
  background-color: #e09300;
}

.perk-slot .rectangle-9.state-branch-a .rectangle-15 {
  background-color: #e09300;
}

.perk-slot .rectangle-9.state-locked .rectangle-15 {
  background-color: #e1b96c;
}

.perk-slot .rectangle-9.state-completed .rectangle-15 {
  background-color: #e09300;
}

.perk-slot .rectangle-9.state-unlocked .rectangle-15 {
  background-color: #e0a100;
}

.perk-slot .rectangle-9.state-branch-x .rectangle-15 {
  background-color: #e09300;
}

.perk-slot .rectangle-9.state-branch-b .PT-perkname {
  color: #584a28;
  left: 7px;
  top: 6px;
}

.perk-slot .rectangle-9.state-branch-a .PT-perkname {
  color: #584a28;
  left: 7px;
  top: 6px;
}

.perk-slot .rectangle-9.state-locked .PT-perkname {
  color: #584a28;
  left: 7px;
  top: 6px;
}

.perk-slot .rectangle-9.state-completed .PT-perkname {
  color: #673d0d;
  left: 10px;
  top: 9px;
}

.perk-slot .rectangle-9.state-unlocked .PT-perkname {
  color: #584a28;
  left: 7px;
  top: 6px;
}

.perk-slot .rectangle-9.state-branch-x .PT-perkname {
  color: #584a28;
  left: 7px;
  top: 6px;
}
.perk-slot .image-perk>* { margin-left: 10px; } .perk-slot .image-perk>*:first-child { margin-left: 0; }
.perk-slot .img-wrapper>* { margin-left: 10px; } .perk-slot .img-wrapper>*:first-child { margin-left: 0; }
.perk-slot .perkimg-wrapper>* { margin-left: 10px; } .perk-slot .perkimg-wrapper>*:first-child { margin-left: 0; }
