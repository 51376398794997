.PGB-button {
  border-radius: 5px;
  height: 47px;
  width: 170px;
}

.PGB-button .overlap-group-14 {
  height: 47px;
  position: relative;
  top: -1px;
}

.PGB-button .perkgroup {
  align-items: center;
  display: flex;
  flex-direction: column;

  height: 31px;
  justify-content: center;
  left: 26px;
  position: absolute;
  top: 9px;
  width: 117px;
}

.PGB-button .perkgroup-hgap {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;

  justify-content: center;
  position: relative;
  width: 100%;
}

.PGB-button .v-d {
  height: 1.5px;
  margin-left: -0.5px;
  position: relative;
  width: 6px;
}

.PGB-button .PT-perkgroup {
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.22px;
  line-height: 11px;
  margin-top: -1px;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.PGB-button .v-af-cda {
  height: 1.5px;
  margin-right: -0.5px;
  position: relative;
  width: 6px;
}

.PGB-button .PT-perkgroupsub {
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.PGB-button .text-wrapper-8 {
  color: #00000003;
  font-family: "Inter", Helvetica;
  font-size: 1px;
  font-weight: 400;
  height: 47px;
  left: 0;
  letter-spacing: 0;
  line-height: 1px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 0;
  width: 170px;
}

.PGB-button.on {
  background-color: #553d0e;
  border: 2px solid;
  border-color: #42300d;
  box-shadow: inset 0px 1px 0px #36270a;
}

.PGB-button.off {
  background-color: #fae28d;
  border: 1px solid;
  border-color: #c09d58;
  box-shadow: 0px 1px 0px #d5b856, inset 0px 2px 0px #fff7b2;
}

.PGB-button.on .PT-perkgroup {
  color: #bc9864;
}

.PGB-button.off .PT-perkgroup {
  color: #a78249;
}

.PGB-button.on .PT-perkgroupsub {
  color: #f6e3b2;
}

.PGB-button.off .PT-perkgroupsub {
  color: #684819;
}
.PGB-button .perkgroup>* { margin-top: 4px; } .PGB-button .perkgroup>*:first-child { margin-top: 0; }
.PGB-button .perkgroup-hgap>* { margin-left: 7px; } .PGB-button .perkgroup-hgap>*:first-child { margin-left: 0; }
