.contract-plan-option {
  align-items: center;
  background-color: #f8df89;
  border: 1px solid;
  border-color: #d9bb6d;
  border-radius: 4px;
  display: flex;

  height: 42px;
  justify-content: center;
  position: relative;
  width: 176.33px;
}

.contract-plan-option .button-radio-onoff-wrapper {
  height: 20px;
  position: relative;
  width: 20px;
}

.contract-plan-option .button-radio-onoff-instance {
  width: unset !important;
}

.contract-plan-option .instance-node {
  width: 20px !important;
}

.contract-plan-option .title {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-plan-option .on-click-dummy {
  color: #00000003;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 40px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 0;
  width: 174px;
}

.contract-plan-option.unchecked-disabled {
  opacity: 0.4;
}

.contract-plan-option.checked-disabled {
  opacity: 0.4;
}
.contract-plan-option>* { margin-left: 10px; } .contract-plan-option>*:first-child { margin-left: 0; }
