.simple-change-button {
  height: 27px;
  width: 92px;
}

.simple-change-button .overlap-3 {
  height: 36px;
  left: -429px;
  position: relative;
  top: -7px;
  width: 525px;
}

.simple-change-button .change-button {
  height: 27px;
  left: 429px;
  position: absolute;
  top: 5px;
  width: 92px;
}

.simple-change-button .overlap-group-3 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 0;
  width: 92px;
}

.simple-change-button .rounded-rectangle-2 {
  background-color: #ffe486;
  border: 1px solid;
  border-color: #c09d58;
  border-radius: 4px;
  box-shadow: inset 0px -2px 0px #dab52f;
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 92px;
}

.simple-change-button .title-bold {
  color: #866014;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}

.simple-change-button .frame-11 {
  height: 27px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 24px;
}

.simple-change-button .polygon {
  height: 5px;
  left: -422px;
  position: absolute;
  top: 12px;
  width: 6px;
}

.simple-change-button .BG {
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 525px;
}

.simple-change-button .on-click-dummy-2 {
  color: #00000003;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 0;
  width: 521px;
}
