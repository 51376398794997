@tailwind base;
@tailwind components;
@tailwind utilities;

p {
  margin: 0;
  padding: 0;
}

.box-root>.box {
  margin: 0;
  break-inside: avoid-column;
}

.box {
  border: 1px solid gray;
  padding: 0.25em 0.5em;
  margin: 0.5em 0em;
}

.selected {
  background: green;
}

.over {
  background-color: lightgray;
}

.progress {
  display: inline-block;
  text-align: center;
  margin: 2px 5px;
}

.progress-text {
  vertical-align: middle;
}

canvas {
  image-rendering: -webkit-optimize-contrast;
  /* webkit */
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

table {
  border-collapse: collapse;
}

th {
  background-color: lightgray;
}

code {
  background: lightgray;
  color: blue;
  font-weight: bold;
}

button {
  @apply font-bold bg-gray-300 hover:bg-gray-400 border border-gray-600 px-1;
}

button:disabled {
  @apply bg-gray-200 border-gray-400 text-gray-600 cursor-not-allowed;
}

select {
  margin: 0em 0.25em;
}

ul {
  @apply list-disc list-inside;
}

input[type=text] {
  border: 1px solid black;
  border-radius: .3em;
  margin: 1px;
}

.sep {
  border: 1px solid gray;
  margin: 0em 0.6em;
}

textarea {
  resize: both;
}

.seplist>*:not(:last-child):after {
  content: "|";
  font-weight: bold;
  margin: 0em 0.2em;
}

.seplist2>*:not(:last-child):after {
  content: "/";
  margin: 0em 0.1em;
}

