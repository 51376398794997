.panel-contract-plan {
  height: 389px;
  position: relative;
  width: 705px;
}

.panel-contract-plan .contract-info-2 {
  height: 333px;
  left: 0;
  position: absolute;
  top: 0;
  width: 705px;
}

.panel-contract-plan .overlap-4 {
  background-image: url(../../../static/anima/img/table.svg);
  background-size: 100% 100%;
  height: 256px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 705px;
}

.panel-contract-plan .contract-option-2 {
  height: 43px;
  left: 20px;
  position: absolute;
  top: 196px;
  width: 672px;
}

.panel-contract-plan .contract-options {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;

  height: 40px;
  left: 146px;
  position: absolute;
  top: 0;
  width: 535px;
}

.panel-contract-plan .contract-option-long {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
  width: unset !important;
}

.panel-contract-plan .contract-plan-option-instance {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  margin-bottom: -1px !important;
  margin-top: -1px !important;
  width: unset !important;
}

.panel-contract-plan .contract-plan-option-2 {
  background-image: url(../../../static/anima/img/vector-9.svg) !important;
}

.panel-contract-plan .contract-plan-option-3 {
  background-image: url(../../../static/anima/img/vector-10.svg) !important;
}

.panel-contract-plan .contract-plan-option-4 {
  background-image: url(../../../static/anima/img/button-radio-1.svg) !important;
  width: unset !important;
}

.panel-contract-plan .contract-plan-option-5 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  margin-bottom: -1px !important;
  margin-right: -1px !important;
  margin-top: -1px !important;
  width: unset !important;
}

.panel-contract-plan .title-2 {
  color: #906905;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.panel-contract-plan .contract-detail {
  height: 89px;
  left: 19px;
  position: absolute;
  top: 83px;
  width: 627px;
}

.panel-contract-plan .frame-12 {
  height: 18px;
  left: 356px;
  position: absolute;
  top: 36px;
  width: 251px;
}

.panel-contract-plan .title-3 {
  color: #906905;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.panel-contract-plan .title-4 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 230px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.panel-contract-plan .frame-13 {
  height: 18px;
  left: 356px;
  position: absolute;
  top: 0;
  width: 271px;
}

.panel-contract-plan .title-5 {
  color: #906905;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.panel-contract-plan .frame-14 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;
  flex-direction: column;

  left: 0;
  position: absolute;
  top: 0;
}

.panel-contract-plan .frame-15 {
  height: 18px;
  position: relative;
  width: 276px;
}

.panel-contract-plan .title-6 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 195px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.panel-contract-plan .frame-16 {
  height: 18px;
  position: relative;
  width: 196px;
}

.panel-contract-plan .frame-17 {
  height: 18px;
  position: relative;
  width: 210px;
}

.panel-contract-plan .title-7 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 606px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 46px;
  white-space: nowrap;
}

.panel-contract-plan .title-wrapper {
  background-color: #f8df89;
  height: 32px;
  left: 432px;
  position: absolute;
  top: 37px;
  width: 144px;
}

.panel-contract-plan .title-8 {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.panel-contract-plan .title-9 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 166px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 46px;
  white-space: nowrap;
}

.panel-contract-plan .title-bold-wrapper {
  background-color: #f8df89;
  height: 32px;
  left: 2px;
  position: absolute;
  top: 37px;
  width: 138px;
}

.panel-contract-plan .title-10 {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.panel-contract-plan .title-11 {
  color: #ffe99d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 11px;
  width: 703px;
}

.panel-contract-plan .department {
  height: 54px;
  left: 14px;
  position: absolute;
  top: 270px;
  width: 682px;
}

.panel-contract-plan .frame-18 {
  height: 23px;
  left: 0;
  position: absolute;
  top: 31px;
  width: 682px;
}

.panel-contract-plan .frame-19 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;

  left: 346px;
  position: absolute;
  top: 0;
  width: 336px;
}

.panel-contract-plan .frame-20 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.panel-contract-plan .frame-21 {
  align-items: center;
  background-color: #cea62f;
  display: inline-flex;
  flex: 0 0 auto;

  padding: 4px 7px;
  position: relative;
}

.panel-contract-plan .title-12 {
  color: #ffe084;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.panel-contract-plan .frame-22 {
  height: 23px;
  margin-right: -2px;
  position: relative;
  width: 9px;
}

.panel-contract-plan .title-13 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 23px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 150px;
}

.panel-contract-plan .frame-23 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;

  left: 0;
  position: absolute;
  top: 0;
  width: 336px;
}

.panel-contract-plan .title-14 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 23px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 107px;
}

.panel-contract-plan .frame-24 {
  height: 27px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 673px;
}

.panel-contract-plan .overlap-5 {
  background-color: #f8df89;
  height: 32px;
  left: -19px;
  position: absolute;
  top: -12px;
  width: 173px;
}

.panel-contract-plan .title-15 {
  color: #906905;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 13px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.panel-contract-plan .vector-3 {
  height: 38px;
  left: 156px;
  position: absolute;
  top: -17px;
  width: 1px;
}

.panel-contract-plan .overlap-6 {
  background-image: url(../../../static/anima/img/bg-10.svg);
  background-size: 100% 100%;
  height: 35px;
  left: 159px;
  position: absolute;
  top: -15px;
  width: 525px;
}

.panel-contract-plan .title-16 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

.panel-contract-plan .simple-change-button-instance {
  left: 429px !important;
  position: absolute !important;
  top: 6px !important;
}

.panel-contract-plan .swap-dummy-contract {
  height: 52px !important;
  left: 7px !important;
  position: absolute !important;
  top: 340px !important;
  width: 694px !important;
}

.panel-contract-plan .swap-dummy-contract-2 {
  height: 48px !important;
  width: 690px !important;
}

.panel-contract-plan .swap-dummy-contract-3 {
  height: 43px !important;
  white-space: unset !important;
  width: 680px !important;
}
.panel-contract-plan .frame-14>* { margin-top: 18px; } .panel-contract-plan .frame-14>*:first-child { margin-top: 0; }
.panel-contract-plan .contract-options>* { margin-left: 6px; } .panel-contract-plan .contract-options>*:first-child { margin-left: 0; }
.panel-contract-plan .frame-19>* { margin-left: 9px; } .panel-contract-plan .frame-19>*:first-child { margin-left: 0; }
.panel-contract-plan .frame-21>* { margin-left: 5px; } .panel-contract-plan .frame-21>*:first-child { margin-left: 0; }
.panel-contract-plan .frame-23>* { margin-left: 9px; } .panel-contract-plan .frame-23>*:first-child { margin-left: 0; }
