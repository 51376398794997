.agent-detail-group {
  background-color: var(--c-title00);

  flex-direction: column;
  border: 1px solid;
  margin: 0.2em 0.4em;
  padding: 0.2em 0.4em;
}

.agent-detail-stat {
  color: var(--c-grid00);
  flex-direction: column;
  margin: 1em;
}

.agent-detail-stat>div {
  @apply agent-detail-group;
}

.agent-detail-perk {
  color: var(--c-grid00);
  flex-direction: column;
  margin: 1em;
  height: 90%;
  overflow: auto;
}

.agent-detail-perk div {
  flex-direction: column;
}

.agent-detail-perk-header,
.agent-detail-perk-lv {
  @apply agent-detail-group;
}

.stat-perk-off {
  color: gray;
}

.stat-perk-avail {
  color: lightgray;
}

.stat-perk-on {
  color: #c8c;
}

.agent-stat-gauges td:first-child {
  flex: 0 0 80px;
}

.agent-stat-gauges {
  display: table;
}

.agent-stat-gauges thead {
  display: table-header-group;
}

.agent-stat-gauges tbody {
  display: table-row-group;
}

.agent-stat-gauges tr {
  display: table-row;
}

.agent-stat-gauges td {
  display: table-cell;
}

.agent-stat-gauges .agent-stat-value {
  display: flex;
  align-items: center;
}

.agent-stat-gauges .agent-stat-value .agent-stat-progress {
  flex: 0 0 80px;
  margin-right: 10px;
  display: flex;
}

.agent-stat-gauges-help {
  position: relative;
}

.agent-stat-gauges-help-inner {
  position: absolute;
  background-color: var(--c-popup00);
  width: 400px;
  height: 200px;
  top: 20px;
  left: 10px;
  border: 5px solid black;
  z-index: 1;
  padding: 5px;
}

.agent-stat-gauges-help-closebutton {
  position: absolute;
  right: 5px;
}

.agent-stat-gauges-help-title {
  font-size: 1.2em;
  padding-bottom: 5px;
  text-align: center;
}

.agent-stat-gauges-help-body {
  display: flex;
}

.agent-stat-gauges-help-body-image {
  width: 120px;
  height: 100px;
  padding-right: 5px;
}

.agent-stat-gauges-help-body-text {
  font-size: 1em;
}

.agent-profile-name .agent-unavaiable {
  color: var(--c-fh1-condition-nok);
}