.portrait-medium {
  height: 135px;
  width: 112px;
}

.portrait-medium .overlap-group-7 {
  height: 145px;
  left: -3px;
  position: relative;
  top: -6px;
  width: 119px;
}

.portrait-medium .frame-30 {
  height: 145px;
  left: 0;
  position: absolute;
  top: 0;
  width: 119px;
}

.portrait-medium .frame-31 {
  background-color: #6b5142;
  border: 4px solid;
  border-color: #e8dfbd;
  height: 143px;
  left: 1px;
  position: relative;
  top: 1px;
  transform: rotate(-0.75deg);
  width: 117px;
}

.portrait-medium .image-portrait {
  height: 133px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  transform: rotate(-0.75deg);
  width: 111px;
}

.portrait-medium .rectangle {
  height: 133px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(0.75deg);
  width: 111px;
}
