.item-title {
  background-image: url(../../../static/anima/img/bg-27.svg);
  background-position: 50% 50%;
  background-size: cover;
  height: 24px;
  width: 153px;
}

.item-title .text-area {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;
  flex-direction: column;

  left: 4px;
  position: relative;
  top: 4px;
  width: 144px;
}

.item-title .title-hgap {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;

  position: relative;
  width: 100%;
}

.item-title .deco {
  background-color: #d1a563;
  height: 13px;
  position: relative;
  width: 2px;
}

.item-title .title-17 {
  color: #906905;
  flex: 1 1 0;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 15px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}
.item-title .text-area>* { margin-top: 10px; } .item-title .text-area>*:first-child { margin-top: 0; }
.item-title .title-hgap>* { margin-left: 6px; } .item-title .title-hgap>*:first-child { margin-left: 0; }
