.panel-summary {
  height: 375px;
  position: relative;
  width: 709px;
}

.panel-summary .modifier-info {
  height: 100px;
  left: 359px;
  overflow: hidden;
  position: absolute;
  top: 275px;
  width: 350px;
}

.panel-summary .overlap-group-6 {
  height: 80px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 350px;
}

.panel-summary .textline-vgap {
  height: 69px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 350px;
}

.panel-summary .content-area-instance {
  height: 80px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.panel-summary .item-title-instance {
  background-image: url(../../../static/anima/img/bg-19.svg) !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 350px !important;
}

.panel-summary .item-title-2 {
  width: 341px !important;
}

.panel-summary .traits-info {
  height: 100px;
  left: 359px;
  overflow: hidden;
  position: absolute;
  top: 165px;
  width: 350px;
}

.panel-summary .equipment-info {
  height: 210px;
  left: 0;
  position: absolute;
  top: 165px;
  width: 350px;
}

.panel-summary .button-equipments {
  background-color: #f8df89;
  height: 32px;
  left: 4px;
  position: absolute;
  top: 176px;
  width: 342px;
}

.panel-summary .equipmentinfo {
  align-items: center;
  display: flex;

  height: 26px;
  left: 6px;
  position: relative;
  top: 2px;
  width: 331px;
}

.panel-summary .icon-equipment-instance {
  height: 32px !important;
  left: unset !important;
  margin-bottom: -4px !important;
  margin-left: -2px !important;
  margin-top: -2px !important;
  position: relative !important;
  top: unset !important;
  width: 31px !important;
}

.panel-summary .line {
  background-color: #c19d40;
  height: 21px;
  position: relative;
  width: 1px;
}

.panel-summary .firearm-hgap {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;

  position: relative;
}

.panel-summary .frame-26 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;
  position: relative;
}

.panel-summary .pt-vest-name {
  color: #3d1e0d;
  flex: 1 1 0;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 17px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.panel-summary .firearm-info {
  height: 132px;
  left: 5px;
  position: absolute;
  top: 35px;
  width: 340px;
}

.panel-summary .overlap-8 {
  height: 132px;
  position: relative;
  width: 342px;
}

.panel-summary .firearm-shape-instance {
  left: 2px !important;
  position: absolute !important;
  top: 22px !important;
}

.panel-summary .overlap-wrapper {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 342px;
}

.panel-summary .equipmentinfo-wrapper {
  background-color: #f8df89;
  height: 32px;
  left: 2px;
  position: relative;
  width: 338px;
}

.panel-summary .equipmentinfo-hgap {
  align-items: center;
  display: flex;

  height: 26px;
  left: 4px;
  position: relative;
  top: 2px;
  width: 293px;
}

.panel-summary .icon-equipment-2 {
  height: 32px !important;
  left: unset !important;
  margin-bottom: -4px !important;
  margin-left: -6px !important;
  margin-top: -2px !important;
  position: relative !important;
  top: unset !important;
  width: 39px !important;
}

.panel-summary .firearm-2 {
  align-items: center;
  display: flex;

  margin-bottom: -0.5px;
  margin-right: -36px;
  margin-top: -0.5px;
  position: relative;
  width: 291px;
}

.panel-summary .firearm-grade {
  height: 27px;
  position: relative;
  width: 23px;
}

.panel-summary .pt-firearm-rate-wrapper {
  background-image: url(../../../static/anima/img/bg-20.svg);
  background-size: 100% 100%;
  height: 26px;
  position: relative;
}

.panel-summary .pt-firearm-rate {
  color: #e57610;
  font-family: "Freshman", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 6px;
  white-space: nowrap;
}

.panel-summary .pt-firearm-ty-wrapper {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  background-color: #705125;
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 4px 5px 3px;
  position: relative;
}

.panel-summary .pt-firearm-ty-2 {
  color: #e7d7af;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 11px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.panel-summary .pt-firearm-name {
  color: #3d1e0d;
  flex: 1 1 0;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 17px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
}

.panel-summary .vector-4 {
  height: 2px;
  left: 2px;
  position: absolute;
  top: 31px;
  width: 338px;
}

.panel-summary .baseinfo {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;

  left: 5px;
  position: absolute;
  top: 0;
}

.panel-summary .profile-2 {
  height: 155px;
  position: relative;
  width: 704px;
}

.panel-summary .level-grade {
  height: 79px;
  left: 135px;
  position: absolute;
  top: 76px;
  width: 568px;
}

.panel-summary .grade {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  background-color: #f8df89;
  display: flex;
  height: 75px;
  justify-content: center;
  left: 264px;
  padding: 13px 0px;
  position: absolute;
  top: 4px;
  width: 304px;
}

.panel-summary .frame-27 {
  height: 46.5px;
  margin-left: -8px;
  position: relative;
  width: 70px;
}

.panel-summary .statgrade-potential {
  margin-left: -8px !important;
}

.panel-summary .stat {
  height: 75px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 253px;
}

.panel-summary .body {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  background-color: #f8df89;
  display: flex;
  flex-direction: column;

  height: 75px;
  justify-content: center;
  left: 61px;
  padding: 0px 13px 0px 12px;
  position: absolute;
  top: 0;
  width: 192px;
}

.panel-summary .agentcard-item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;

  justify-content: center;
  padding: 1px 0px;
  position: relative;
  width: 100%;
}

.panel-summary .title-18 {
  color: #7d490d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.panel-summary .dot-line {
  flex: 1 1 0;
  flex-grow: 1;
  height: 1.5px;
  position: relative;
}

.panel-summary .value-hgap {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;

  justify-content: flex-end;
  padding: 0px 2px;
  position: relative;
}

.panel-summary .bulletproof {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.panel-summary .head {
  align-items: center;
  background-color: #6b5142;
  display: flex;
  flex-direction: column;

  height: 75px;
  justify-content: center;
  left: 0;
  padding: 6px 10px;
  position: absolute;
  top: 0;
  width: 61px;
}

.panel-summary .level {
  color: #d8c1b4;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.panel-summary .grade-2 {
  height: 24.5px;
  position: relative;
  width: 32.75px;
}

.panel-summary .g-2 {
  color: #d8c1b4;
  font-family: "Freshman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: -6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: -1px;
  width: 44px;
}

.panel-summary .frame-28 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;

  left: 136px;
  position: absolute;
  top: 0;
}

.panel-summary .profileitem-age {
  width: 100px !important;
}

.panel-summary .item-box-instance {
  width: 143px !important;
}

.panel-summary .profileitem-age-2 {
  width: 91px !important;
}

.panel-summary .profileitem-age-3 {
  background-image: url(../../../static/anima/img/bg-4.svg) !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 100px !important;
}

.panel-summary .item-box-2 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.panel-summary .agent-portrait {
  background-color: #e4bf60;
  height: 152px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 122px;
}

.panel-summary .overlap-9 {
  height: 145px;
  left: 2px;
  position: relative;
  top: 3px;
  width: 119px;
}

.panel-summary .frame-wrapper {
  height: 135px;
  left: 3px;
  position: absolute;
  top: 6px;
  width: 112px;
}

.panel-summary .frame-29 {
  background-color: #6b5142;
  border: 4px solid;
  border-color: #e8dfbd;
  height: 143px;
  left: -2px;
  position: relative;
  top: -6px;
  transform: rotate(-0.75deg);
  width: 117px;
}

.panel-summary .portrait {
  height: 145px;
  left: 0;
  position: absolute;
  top: 0;
  width: 119px;
}

.panel-summary .bulletitem-hgap1 {
  flex: 0 0 auto !important;
}

.panel-summary .portrait-medium-instance {
  left: 3px !important;
  position: relative !important;
  top: 6px !important;
}
.panel-summary .body>* { margin-top: 5px; } .panel-summary .body>*:first-child { margin-top: 0; }
.panel-summary .head>* { margin-top: 6px; } .panel-summary .head>*:first-child { margin-top: 0; }
.panel-summary .agentcard-item>* { margin-left: 6px; } .panel-summary .agentcard-item>*:first-child { margin-left: 0; }
.panel-summary .baseinfo>* { margin-left: 12px; } .panel-summary .baseinfo>*:first-child { margin-left: 0; }
.panel-summary .equipmentinfo>* { margin-left: 5px; } .panel-summary .equipmentinfo>*:first-child { margin-left: 0; }
.panel-summary .equipmentinfo-hgap>* { margin-left: 5px; } .panel-summary .equipmentinfo-hgap>*:first-child { margin-left: 0; }
.panel-summary .firearm-2>* { margin-left: 4px; } .panel-summary .firearm-2>*:first-child { margin-left: 0; }
.panel-summary .firearm-hgap>* { margin-left: 4px; } .panel-summary .firearm-hgap>*:first-child { margin-left: 0; }
.panel-summary .frame-28>* { margin-left: 3px; } .panel-summary .frame-28>*:first-child { margin-left: 0; }
.panel-summary .value-hgap>* { margin-left: 2px; } .panel-summary .value-hgap>*:first-child { margin-left: 0; }
