.perk-levels {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;
  flex-direction: column;
  padding: 66px 11px;
  position: relative;
}

.perk-levels .perk-level-instance {
  position: relative !important;
}
