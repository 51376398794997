.PGB-splitter {
  height: 35px;
  width: 5px;
}

.PGB-splitter .rectangle-16 {
  background-color: #d9d9d9;
  height: 31px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 1px;
}
