

.portrait-frame {
  position: relative;
  width: 489px;
  height: 567px;

  overflow: hidden;
}

.portraitsview .portrait-frame {
  background-color: #EE5102;
}

.portrait-frame-small0 {
  width: 244px;
  height: 283px;
}

.portrait-frame-small1 {
  width: 122px;
  height: 142px;
}

.portrait-frame-small2 {
  width: 82px;
  height: 95px;
}

.portrait-frame-small3 {
  width: 61px;
  height: 71px;
}

.portrait-part {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.portraitsview-preset-input {
  width:300px;
}
