.hex:before {
  content: " ";
  width: 0; height: 0;
  border-left: 28px solid transparent;
  border-right: 28px solid transparent;
  position: absolute;
  top: -15px;
}

.hex {
  margin-top: 15px;
  width: 56px;
  height: 30px;
  position: relative;

  display: inline-block;
}

.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -15px;
  border-left: 28px solid transparent;
  border-right: 28px solid transparent;
}

.hex-green:before { border-bottom: 15px solid #6C6; }
.hex-green { background-color: #6C6; }
.hex-green:after { border-top: 15px solid #6C6; }

.hex-red:before { border-bottom: 15px solid #C66; }
.hex-red { background-color: #C66; }
.hex-red:after { border-top: 15px solid #C66; }

.hex-blue:before { border-bottom: 15px solid #66C; }
.hex-blue { background-color: #66C; }
.hex-blue:after { border-top: 15px solid #66C; }

.hex-lightgray:before { border-bottom: 15px solid #CCC; }
.hex-lightgray { background-color: #CCC; }
.hex-lightgray:after { border-top: 15px solid #CCC; }

.hex-gray:before { border-bottom: 15px solid #888; }
.hex-gray { background-color: #888; }
.hex-gray:after { border-top: 15px solid #888; }

.hex-darkgray:before { border-bottom: 15px solid #444; }
.hex-darkgray { background-color: #444; }
.hex-darkgray:after { border-top: 15px solid #444; }

.hex-row {
  clear: left;
}

.hex {
  margin-left: 1px;
}
.hex-row.even {
  margin-top: 1px;
  margin-left: 29px;
  margin-bottom: 1px;
}

.hex-inner {
  height: 100%;
  text-align: center;
  vertical-align: middle;
  user-select: none;
}

.btn-selected {
  background: green;
}

.world2-canvas {
  filter: contrast(1.4) brightness(0.7);
}

.world2-canvas-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.world2-info {
  background: white;
  position: absolute;
  right: 0;
  width: 30%;
  z-index: 1;
  height: 100%;
  overflow: scroll;
}
