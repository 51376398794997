.pgbtooltip-SI {
  height: 336px;
  width: 399px;
}

.pgbtooltip-SI .content-3 {
  height: 4px;
  position: relative;
  width: 4px;
}

.pgbtooltip-SI .div-3 {
  height: 334px;
  left: 0;
  position: absolute;
  top: 0;
  width: 398px;
}

.pgbtooltip-SI .subtract-2 {
  height: 329px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 393px;
}

.pgbtooltip-SI .overlap-group-11 {
  background-image: url(../../../static/anima/img/subtract-4.svg);
  background-size: 100% 100%;
  height: 328px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 392px;
}

.pgbtooltip-SI .rectangle-3 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.pgbtooltip-SI .rectangle-4 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.pgbtooltip-SI .subtract-3 {
  height: 322px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 386px;
}

.pgbtooltip-SI .style-dummy {
  color: #00000003;
  font-family: "Inter", Helvetica;
  font-size: 1px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
  width: 4px;
}

.pgbtooltip-SI .contents-holder {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;
  flex-direction: column;

  left: 23px;
  position: absolute;
  top: 23px;
  width: 350px;
}

.pgbtooltip-SI .PGB-tooltip-content-instance {
  flex: 0 0 auto !important;
}
.pgbtooltip-SI .contents-holder>* { margin-top: 20px; } .pgbtooltip-SI .contents-holder>*:first-child { margin-top: 0; }
