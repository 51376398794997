.contract-compact {
  height: 32px;
  width: 363px;
}

.contract-compact .wrapper {
  height: 185px;
  position: relative;
  top: -185px;
  width: 366px;
}

.contract-compact .overlap-2 {
  background-image: url(../../../static/anima/img/bg-13.svg);
  background-size: 100% 100%;
  height: 185px;
  position: relative;
  width: 363px;
}

.contract-compact .expiery {
  align-items: center;
  background-color: #e3cc8e;
  display: flex;

  height: 22px;
  left: 42px;
  padding: 4px 115px 4px 12px;
  position: absolute;
  top: 121px;
  width: 321px;
}

.contract-compact .subtract {
  height: 14.56px;
  margin-bottom: -0.28px;
  margin-top: -0.28px;
  position: relative;
  width: 17.3px;
}

.contract-compact .pt-expiry {
  color: #917429;
  font-family: "Pretendard", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.contract-compact .reward-2 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;
  left: 49px;
  position: absolute;
  top: 80px;
  width: 306px;
}

.contract-compact .frame-4 {
  align-items: center;
  background-color: #41c3a4;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;

  height: 34px;
  justify-content: center;
  padding: 5px 14px;
  position: relative;
}

.contract-compact .frame-5 {
  height: 24px;
  position: relative;
  width: 19px;
}

.contract-compact .pt-renown-2 {
  color: #e8fff9;
  font-family: "Pretendard", Helvetica;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.8px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-compact .frame-6 {
  align-items: center;
  background-color: #de960c;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;

  height: 34px;
  justify-content: center;
  margin-left: -1px;
  padding: 6px 14px;
  position: relative;
}

.contract-compact .frame-7 {
  height: 18px;
  position: relative;
  width: 30px;
}

.contract-compact .pt-money-2 {
  color: #fff4bd;
  font-family: "Pretendard", Helvetica;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.8px;
  line-height: 20px;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.contract-compact .enemy-duration {
  align-items: center;
  display: inline-flex;

  justify-content: center;
  left: 52px;
  position: absolute;
  top: 34px;
}

.contract-compact .map-2 {
  align-items: center;
  display: flex;

  position: relative;
  width: 196px;
}

.contract-compact .frame-8 {
  height: 21px;
  margin-bottom: -2.5px;
  margin-left: -2px;
  margin-top: -2.5px;
  position: relative;
  width: 14px;
}

.contract-compact .pt-map-name-2 {
  color: #583b18;
  font-family: "Pretendard", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-compact .vector-2 {
  height: 37px;
  position: relative;
  width: 1px;
}

.contract-compact .frame-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;

  position: relative;
}

.contract-compact .div-wrapper {
  height: 32px;
  position: relative;
  width: 20px;
}

.contract-compact .overlap-group-2 {
  background-image: url(../../../static/anima/img/union-8.svg);
  background-size: 100% 100%;
  height: 35px;
  position: relative;
  width: 21px;
}

.contract-compact .pt-threat-level-2 {
  color: #ffd0a4;
  font-family: "Pretendard", Helvetica;
  font-size: 15px;
  font-weight: 800;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 16px;
  white-space: nowrap;
}

.contract-compact .frame-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;

  justify-content: flex-end;
  position: relative;
}

.contract-compact .x-2 {
  color: #a70000;
  font-family: "Pretendard", Helvetica;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.28px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-compact .pt-enemy-count-2 {
  color: #a70000;
  font-family: "Pretendard", Helvetica;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.4px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-compact .pt-customer-wrapper {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-color: #6f4a12;
  display: inline-flex;

  justify-content: center;
  left: 54px;
  padding: 0px 0px 1px;
  position: absolute;
  top: 6px;
}

.contract-compact .pt-customer-2 {
  color: #6f4a12;
  font-family: "Pretendard", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.contract-compact .enemy-duration>* { margin-left: 19px; } .contract-compact .enemy-duration>*:first-child { margin-left: 0; }
.contract-compact .expiery>* { margin-left: 5px; } .contract-compact .expiery>*:first-child { margin-left: 0; }
.contract-compact .frame-10>* { margin-left: 1px; } .contract-compact .frame-10>*:first-child { margin-left: 0; }
.contract-compact .frame-4>* { margin-left: 3px; } .contract-compact .frame-4>*:first-child { margin-left: 0; }
.contract-compact .frame-6>* { margin-left: 6px; } .contract-compact .frame-6>*:first-child { margin-left: 0; }
.contract-compact .frame-9>* { margin-left: 5px; } .contract-compact .frame-9>*:first-child { margin-left: 0; }
.contract-compact .map-2>* { margin-left: 7px; } .contract-compact .map-2>*:first-child { margin-left: 0; }
.contract-compact .pt-customer-wrapper>* { margin-left: 10px; } .contract-compact .pt-customer-wrapper>*:first-child { margin-left: 0; }
