html {
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --c-title00: #e9e1c0;
  --c-title01: #ffb400;
  --c-title02: #ff6000;
  --c-title03: #ef5011;

  --c-title10: #f2b75f;
  --c-title11: #e98b3f;

  --c-grid00: #1c1b18;
  --c-grid01: #282421;
  --c-grid02: #4a4a4a;
  --c-grid03: #35414b;
  --c-grid04: #22524a;

  --c-popup00: #e1995e;
  --c-popup01: #f0edd2;

  --c-highlight00: #fc4c07;
  --c-highlight01: #2fe9bf;
  --c-highlight02: #f600ff;
  --c-highlight03: #2def84;

  --c-bg00: #7F7F7F;

  --c-gray00: #373737;
  --c-red00: #742b11;
}

body {
  margin: 0;
  font-size: 12px;
}

code {
  font-family: monospace;
}

strong {
  font-weight: bolder;
  text-decoration: underline;
}

td,
th {
  border: 1px solid;
  padding: 0.25em;
}

.flex-none {
  flex: none !important;
}


