.theredbutton-SI {
  height: 50px;
  width: 232px;
}

.theredbutton-SI .content-2 {
  height: 50px;
}

.theredbutton-SI .overlap-group-9 {
  background-position: 50% 50%;
  background-size: cover;
  height: 54px;
  position: relative;
  width: 232px;
}

.theredbutton-SI .frame-32 {
  align-items: center;
  display: flex;

  height: 50px;
  justify-content: center;
  left: 1px;
  position: absolute;
  top: 0;
  width: 230px;
}

.theredbutton-SI .pt-label-2 {
  color: #ff9b62;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 19px;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.theredbutton-SI .text-wrapper-5 {
  color: #00000003;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 46px;
  left: 2px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 1px;
  width: 228px;
}

.theredbutton-SI.var-state-disabled {
  opacity: 0.45;
}

.theredbutton-SI.var-state-enabled .overlap-group-9 {
  background-image: url(../../../static/anima/img/vectorred-2.svg);
}

.theredbutton-SI.var-state-disabled .overlap-group-9 {
  background-image: url(../../../static/anima/img/vectorred-1.svg);
}
.theredbutton-SI .frame-32>* { margin-left: 23px; } .theredbutton-SI .frame-32>*:first-child { margin-left: 0; }
