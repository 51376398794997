.item-box {
  height: 68px;
  position: relative;
  width: 153px;
}

.item-box .overlap-group-5 {
  height: 45px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 153px;
}

.item-box .text-wrapper-2 {
  color: #ff000003;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 43px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 0;
  width: 153px;
}

.item-box .textline {
  height: 38px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 153px;
}

.item-box .value {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 9px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 134px;
}
