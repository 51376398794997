.bulletitem {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;

  position: relative;
  width: 345px;
}

.bulletitem .bullet-anchor {
  align-self: stretch;
  position: relative;
  width: 15px;
}

.bulletitem .bullet {
  height: 20px;
}

.bulletitem .ellipse {
  background-color: #3d1e0d;
  border-radius: 2px;
  height: 4px;
  left: 6px;
  position: relative;
  top: 8px;
  width: 4px;
}

.bulletitem .item {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;

  padding: 1px 0px;
  position: relative;
}

.bulletitem .pt-bullet-item {
  color: #3d1e0d;
  flex: 1 1 0;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.bulletitem .text-wrapper-3 {
  color: #ff000003;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
  width: 345px;
}
.bulletitem .item>* { margin-left: 10px; } .bulletitem .item>*:first-child { margin-left: 0; }
.bulletitem>* { margin-left: 1px; } .bulletitem>*:first-child { margin-left: 0; }
