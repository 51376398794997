.content-area {
  height: 100px;
  width: 350px;
}

.content-area .no-data {
  position: relative;
  top: 9px;
}

.content-area .nodata-wrapper {
  background-image: url(../../../static/anima/img/vector-26.svg);
  background-size: 100% 100%;
  height: 90px;
  position: relative;
  width: 350px;
}

.content-area .nodata {
  color: #c56b39;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 800;
  left: 128px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 37px;
  transform: rotate(-15deg);
  white-space: nowrap;
}

.content-area .bulletitem-hgap {
  flex: 0 0 auto !important;
}

.content-area.default {
  overflow-y: /* PMC */ scroll; overflow-x: hidden;
}

.content-area.default .no-data {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;
  flex-direction: column;

}

.content-area.no-data .no-data {
  height: 89px;
}
.content-area.default .no-data>* { margin-top: 8px; } .content-area.default .no-data>*:first-child { margin-top: 0; }
