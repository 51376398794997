* {
  font-family: 'The Jamsil OTF';
}

html,
body,
#root {
  height: 100%;
}

p {
  display: flex;
  flex-wrap: wrap;
}

button {
  font-family: 'The Jamsil OTF';
}

/* <zIndex> */

/* worldmap */
.flex-worldmap {
  z-index: 11;
}

/* corner */
.flexbg-lb,
.flexbg-lt,
.flexbg-rb,
.flexbg-rt {
  z-index: 12;
}

/* worldmap overlay */
.flexoverlay {
  z-index: 13;
}

/* film rotation */
.flex-player {
  z-index: 14;
}

/* bottom journal */
.flex-info-root {
  z-index: 15;
}

/* left wing 0 */
.flexleft {
  position: relative;
  z-index: 16;
  pointer-events: auto;
}

.flexleft0 {
  align-self: center;
  width: 150px;
  pointer-events: none;
}

.flexleft-icons {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 60px;
}

.flexleft-icon-container {
  position: relative;
  width: 60px;
  height: 60px;
  margin-left: -3px;
  margin-bottom: -8px;
}

.flexleft-icon {
}

.flexleft-icon-disabled {
  filter: brightness(0) saturate(100%) invert(32%) sepia(10%) saturate(5%) hue-rotate(325deg) brightness(104%) contrast(86%);
}

.flexleft-icon-unselected:hover {
  filter: brightness(0) saturate(100%) invert(81%) sepia(26%) saturate(6730%) hue-rotate(5deg) brightness(104%) contrast(102%);
}

.flexleft-lupe {
  position: absolute;
  top: 5px;
  left: -25px;
}

/* left wing 1 */
.flexleft1 {
  z-index: 17;
  width: 0px;
  overflow: hidden;
}

/* top left */
.flextopleft {
  z-index: 18;
}

/* right wing 0 */
.flexright {
  z-index: 19;
  pointer-events: none;
}

.flexright > * {
  pointer-events: auto;
}

/* right wing 1 */
.flexright1 {
  z-index: 20;
}

/* map option buttons */
.flexmapoptions {
  z-index: 21;
}

/* debug buttons */
.flexdebug {
  z-index: 22;
}

/* main ui */
.flexcenter {
  z-index: 23;
}
.flexcenter>.fh1-window {
  margin-top: var(--card-top);
}

/* modal overlay */
.overlay-root {
  z-index: 24;
}

.tooltip-floater {
  z-index: 25;
}

/* </zIndex> */

.tooltip,
.flexroot *[title] {
  text-decoration: 2px underline;
  text-decoration-color: var(--c-fh1-button-border);

  cursor: help;
  user-select: none;
}

.tooltip-floater {
  position: absolute;
  border: 1px solid black;
  border-width: 1px 2px 2px 1px;
  background: #454134;
  padding: 1rem;
  min-width: 8rem;
  max-width: 16rem;
}

.tooltip-floater>h2 {
  font-weight: bold;
}


.flexroot table {
  background-color: var(--c-fh1-table-bg);
  color: var(--c-fh1-table-fg);
}

.flexroot td, .flexroot th {
  border: none;
}

.flexroot thead tr th, .flexroot thead tr td {
  background-color: var(--c-fh1-table-thead-bg);
  color: var(--c-fh1-table-thead-fg);
  border-right: 1px solid var(--c-fh1-table-border);
  border-bottom: 1px solid var(--c-fh1-table-border);
}

.flexroot thead tr td {
  border-right: unset;
}

.flexroot tbody tr th {
  background-color: var(--c-fh1-table-th-bg);
  color: var(--c-fh1-table-th-fg);
  border: none;
}

.flexroot tbody tr td {
  border: none;
}

.flexroot tbody tr td.dt-short.disabled {
  color: var(--c-fh1-table-disabled-fg);
}

.flexroot span.delta-positive {
  color: var(--c-fh1-delta-positive);
}

.flexroot span.delta-negative {
  color: var(--c-fh1-delta-negative);
}

.flexroot button[title] {
  background: #626035;
}

.flex-worldmap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.popupcard {
  max-height: 90vh;
  border: 0.5em solid var(--c-popup00);
  color: var(--c-grid00);
  background-color: var(--c-title00);

  margin-top: var(--card-top);
  margin-left: 1em;
}

.popupcard button,
.overlay button {
  color: var(--c-title00);
  background-color: var(--c-grid02);
  border: 2px solid #525328;
}

.flex-card-long {
  display: flex;
  flex-direction: column;

  max-width: 80vw;
  /* TODO: gameface clipping 버그
  overflow: scroll;
  */
}

.flex-card-short {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.flex-plan {
  display: flex;
  flex-direction: column;
  width: 650px;
}

.flexbg-lt {
  position: absolute;
  left: 0;
  top: 0;
}

.flexbg-lb {
  position: absolute;
  left: 0;
  bottom: 0;
}

.flexbg-rt {
  position: absolute;
  right: 0;
  top: 0;
}

.flexbg-rb {
  position: absolute;
  right: 0;
  bottom: 0;
}

.flex-player {
  position: absolute;
  top: 0;
  right: 0;

  overflow: hidden;
  height: 10em;
}

.flex-player-datetime {
  position: absolute;
  top: 0.6em;
  right: 295px;

  text-align: right;
  line-height: 1.2em;
  color: #f4f1d7;
}

.flex-player-date {
  font-size: 1.2em;
  font-weight: 800;
}

.flex-player-time {
  font-size: 0.8em;
}

.flex-player-bg {
  width: 511px;
  height: 43px;
  margin-right: 40px;
}

.flex-player-film {
  position: absolute;
  top: 0;
  right: -2em;

  width: 417px;
  height: 55px;
}

.flex-player-daynight {
  position: absolute;
  top: 0em;
  right: 3em;

  width: 121px;
  height: 67px;
}

.flex-player-reel {
  position: absolute;
  top: -56px;
  right: -62px;
  animation-name: spinner-spins;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  width: 154px;
  height: 154px;
}

.flex-player-btns {
  position: absolute;
  top: 0em;
  right: 85px;

  display: flex;
  flex-direction: row;
}

.flex-player-btn2 {
  width: 40px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.flex-player-btn2 > * {
  position: absolute;
  top: 0;
}

.flex-player-speedo {
  width: 51px;
  height: 30px;
  margin-left: -1px;
  margin-right: -1px;
}

.flex-player-btn {
  margin: 2px;
  width: 46px;
  height: 34px;
  line-height: 34px;

  font-weight: bold;
  font-size: 1.4em;
  text-align: center;
  color: var(--c-grid01);
  background-color: var(--c-title00);
  border: 2px solid;
  border-color: #282421;
}

.flex-player-btn-selected {
  border-color: var(--c-fh1-highlight-fg);
}

.flexroot {
  display: flex;
  min-height: 100vh;
  height: 100%;
  flex-direction: row;
  align-items: flex-start;

  overflow: hidden;

  --height: 3em;
  --card-top: 80px;

  font-family: 'The Jamsil OTF';
  color: #C6BF9E;
  user-select: none;
}

.grid02 {
  color: var(--c-title00);
  background-color: var(--c-grid02);
}

.grid03 {
  color: var(--c-title00);
  background-color: var(--c-grid03);
}

.grid04 {
  color: var(--c-title00);
  background-color: var(--c-grid04);
}

.flexroot-embed {
  background: inherit;
}

.flextopleft {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.flextopleft img {
  object-fit: contain
}

.flextopleftbar {
  margin: 0em 0.4em;
  position: relative;
}

.flextopleftbar-money {
  width: 208px;
  height: 49px;
}

.flextopleftbar-rep {
  width: 156px;
  height: 42px;
}

.flextopleftbar span {
  position: absolute;
  top: 0.55em;
  font-size: 1.2em;
  font-weight: bold;
  right: 1.1em;
}

.flexleft1 {
  margin: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  pointer-events: none;
}

.flexleftbtn {
  margin: 0.4em 0em;
  position: relative;
  left: -25px;
  width: 100px;
  height: 55px;
  pointer-events: auto;
}

.flexleftbtn-selected {
  margin-left: 20px;
}

.flexleftbtn-folder {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}

.flexleftbtn-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
}

.flexleft>div {
  margin: 0.4em 0.8em;
}

.flexleft-resource {
  display: flex;
  padding: 0em 1em;
  background-color: var(--c-grid00);
}

.flexleft-resource-label {
  color: var(--c-title00);
}

.flexleft-resource-value {
  color: var(--c-fh1-highlight-fg);
  margin-left: auto;
  color: #FDC536;
}


.flexleft-time {
  color: var(--c-title00);
  background-color: var(--c-grid00);
  border: 1px solid #4B473D;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexleft-time-btngroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.1em 0.2em;
}

button.flexleft-time-btn {
  display: flex;
  flex: 1;

  background-color: var(--c-grid01);

  border: inherit;
  margin: 0.1em 0.2em;
}

button.flexleft-time-btn:disabled {
  color: var(--c-highlight01);
  background-color: var(--c-grid03);
}

button.district-btn {
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);

  color: #D4C782;
  background: #4E672E;
  border: 1px solid #384926;
}

button.district-btn:disabled {
  background: #626035;
}

.flexleft-sep {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.flexcenter {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-start;
  max-height: 100%;
  /* TODO: gameface clipping 버그
  overflow-x: hidden;
  overflow-y: auto;
  */

  pointer-events: none;
}

.flexcenter>* {
  pointer-events: auto;
}

.flexcenter>.box {
  width: 100%;
  border: 1px solid transparent;
  margin: 1em;
  padding: 0em;
}

.overlay-root .flex-agents-figmaroot {
  left: 146px;
}

.flexright {
  position: absolute;
  right: 0;
  top: 8em;
  bottom: 20em;
  width: 280px;

  max-height: 80%;
  overflow: auto;
}

.flexright .fh1-wellseg {
  border: 1px solid var(--c-fh1-drawer-border);
}

.flexright .fh1-wellseg .fh1-title {
  color: var(--c-fh1-drawer-title-fg);
  background-color: var(--c-fh1-drawer-title-bg);
  padding: 3px 7px 1px;
}

.flexright1 {
  @apply flexright;
  width: 320px;
  right: 270px;
  bottom: inherit;
}

.flexright>div {
  margin: 1em;
  pointer-events: auto;
}

.flexright-renown {
  background-color: var(--c-fh1-drawer-bg);
  font-size: 1em;
  padding: 0.5em;
}

.flexright-renown-top {
  background-color: var(--c-fh1-drawer-bg);
}

.flexright-renown-milestone {
  background-color: var(--c-fh1-drawer-bg);
  text-align: left;
  padding: 0.2em 0.4em;
}

.flexright-renown-title {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.3em 0.6em;
}

.flexright-renown-label1 {
  flex: 3;
  color: var(--c-fh1-drawer-fg);
}

.flexright-renown-label2 {
  flex: 1;
  color: var(--c-fh1-drawer-fg);
  padding: 0 10px;
  border-left: var(--c-fh1-drawer-fg) solid 2px;
}

.flexright-renown-data {
  background: var(--c-fh1-label-bg);
  color: var(--c-fh1-label-fg);
  width: 100%;
  padding: 0 1em;
}

.flexright-renown-label3 {
  display: flex;
  color: var(--c-fh1-drawer-fg);;
  text-align: center;
}

.renown-yellow {
  color: #FFD600;
}

.renown-red {
  background-color: #6D0000;
  color: red;
  padding: 0 1em;
}

.flexright-renown-progress {
  display: flex;
  align-items: center;
  height: 20px;
  color: var(--c-grid00);
  background-color: var(--c-title00);

  position: relative;
}

.flexright-renown-bar {
  height: 100%;
  background-color: var(--c-fh1-highlight-fg);
}

.flexright-renown-showoverview {
  color: var(--c-title01);
}

.flexright-renown-text {
  display: flex;
  justify-content: center;
  color: var(--c-grid00);
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
}

.flexright-renown-milestone-desc {
  padding: 0.5em;
  color: var(--c-fh1-drawer-fg);
}

.flexright-upcoming {
  margin: 0.3em 0;
  padding: 0.5em;
  color: var(--c-title00);
  background-color: var(--c-grid02);
}

.flexright-upcoming-complete {
  @apply flexright-upcoming;
  color: var(--c-highlight03);
}

.flexright-upcoming div {
  display: flex;
}

.minusbtn {
  margin-left: auto;
  color: #F9D762;
  width: 1.5em;
  height: 1.5em;
}

.flexright-trainings {
  color: var(--c-title00);
  background-color: var(--c-grid02);
  padding: 0.5em;
}

.flexright-missions {
  color: var(--c-title00);
  background-color: var(--c-grid02);
  padding: 0.5em;
}

.flexright-title {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.flexright-title-left {
  color: white;
}

.flexright-title-right {
  margin-left: auto;
  color: #4CDBB9;
}

.flexright-btn {
  background-color: inherit;
  border: none;
  color: white;
}

.flexright-body {
  max-height: 134px;

  overflow-y: auto;
}

.flexright-body-item {
  display: flex;

  align-items: center;
  flex-direction: row;
  height: 30px;
  border-bottom: 1px dashed gray;
}

.flexright-body-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flexright-body-item-middle {
  flex: 0.4;
}

.flexright-body-item-time {
  flex: 3;
  padding-right: 0.5em;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flexright-questitem {
  white-space: pre-line;
  margin: 0.5em 0em;
  display: flex;
  flex-direction: column;
}

.flexright-questitem-claimable {
  background-color: var(--c-highlight03);
  color: var(--c-grid00);
  white-space: pre-line;
  margin: 0.5em 0em;
  padding: 0.5em;
}

.flexright-questitem-title {
  font-size: 1.1em;
}

.flexright-questitem-title-button {
  background-color: inherit;
  border: none;
}

.flexright-questitem.special {
  background-color: var(--c-fh1-overlay-flex-special-bg);
  color: var(--c-fh1-overlay-flex-special-fg);
}

.checklist.box {
  background-color: var(--c-fh1-condition-box-bg);
  margin: 0.5em;
}

.flexright-questitem.special .fh1-button-foldable {
  background-color: var(--c-fh1-button-foldable-special-bg);
  color: var(--c-fh1-button-foldable-special-fg);
  border-color: var(--c-fh1-button-foldable-special-border);
}

.flexright-questitem .box.desc {
  max-height: 140px;
  overflow-y: auto;
}

.flexright-questitem.special .box.desc {
  background-color: var(--c-fh1-overlay-flex-special-bg);
  color: var(--c-fh1-button-foldable-special-fg-desc);
  border: none;
}

.flex-questitem-objective {
  font-size: 1em;
}

.flexcontent-world {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;

  color: #787878;
}

.flexcontent-world>div {
  display: flex;
  flex-direction: column;
  align-items: top;
  padding: 1em;
}

.flexcontent-world>div:hover {
  background: #001a20;
}

.agent-thumbnail {
  width: 82px;
  height: 95px;
}

.agent-thumbnail>.portrait-frame {
  width: 82px;
  height: 95px;
}

.flexroot .portrait-frame {
  z-index: 1;
  border-radius: 5px;
  border: 1px solid #C26900;
  background-color: #C09C65;
}

.flexroot .portrait-frame::before {
  content: '';
  position: absolute;
  z-index: 2;
  background-color: transparent;
  top: 0px;
  left: 0px;
  min-width: 30px;
  min-height: 30px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #FFD075;
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.flexroot .portrait-frame::after {
  content: '';
  position: absolute;
  z-index: 2;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g filter="url(%23filter0_d_404_1502)"><path d="M0 14C0 15.6569 1.34315 17 3 17H17V3C17 1.34315 15.6569 0 14 0H0V14Z" fill="%23FFD075"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M12.148 8.99736C13.5329 7.22421 13.565 4.69451 12.0762 2.87238C10.3205 0.723648 7.11482 0.372565 4.91601 2.08825C2.7172 3.80391 2.35795 6.93663 4.11361 9.08536C5.60244 10.9076 8.13409 11.4369 10.2102 10.5095L13.0622 14L15 12.4879L12.148 8.99736ZM10.0855 4.42563C10.9634 5.49999 10.7837 7.06635 9.68434 7.92418C8.58493 8.78202 6.98208 8.60648 6.10426 7.53212C5.22644 6.45775 5.40606 4.89139 6.50546 4.03357C7.60486 3.17573 9.20772 3.35126 10.0855 4.42563Z" fill="%23230F00"/><defs><filter id="filter0_d_404_1502" x="0" y="0" width="17" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_404_1502"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_404_1502" result="shape"/></filter></defs></svg>');
  top: 2px;
  right: 1px;
  width: 17px;
  height: 17px;
}

.flex-agents-detail-portrait .portrait-frame::after, .fh1-panel-left .portrait-frame::after {
  background-image: none;
}

.agent-profile {
  display: flex;
  flex-direction: column;
  border: 5px solid;
  border-color: transparent;
}

.agent-profile:hover {
  box-shadow: 5px 5px #888;
}

.agent-profile-selected {
  border-color: var(--c-fh1-highlight-fg);
}

.agent-profile-top {
  display: flex;
  flex-direction: row;
}

.agent-profile-bottom {
  display: flex;
  flex-direction: row;
}

.agent-termination-btn {
  background: #5F3333 !important;
  border: 1px solid #6F3434;

  margin-left: auto;
}

.agent-squad-btn {
  background: #6D6A49;
  border: 1px solid #76724C;
  margin: 0em 0.4em;
}

.agent-info {
  flex: 7;
  display: flex;
  flex-direction: column;
}

.agent-info-1 {
  display: flex;
  flex-direction: row;
  flex: 1;
  font-weight: 400;
}

.agent-info-1>* {
  margin-left: 5px;
  margin-right: 5px;
}

.agent-info-3 {
  display: flex;
  flex-grow: 1;
}

.agent-info-3>div {
  flex: 1;
  display: flex;
  align-items: center;
}

.agent-info-3>div>div {
  flex: 1;
  margin: 0.2em;
  display: flex;

  padding: 0.2em;
}

.agent-item-title {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.agent-item-body {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.agent-item-list {
  flex-grow: 1;
  text-align: start;
  background: #1F1D13;
}

.agent-item-list:disabled {
  background: #1F1D13;
}

.agent-profile {
  margin: 0.6em;
  padding: 0.6em;

  @apply grid02;
}

.agent-profile-name {
  text-decoration: underline;
}

.agent-profile-detail-btn {
  background: #5A5541;
}

.agent-power-extra {
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--c-yd-content-fg-desc);

  margin: 0em 0.4em;
  padding: 0em 0.2em;
}

.agent-max-life {
  color: #A19470;
}

.agent-life-max {
  color: #326825;
}

.agent-life {
  color: #6C4827;
}

.agent-life-low {
  color: #A62828;
}

.agent-profile-aptitude {
  width: 2em;
  height: 20px;

  color: gray;
  text-align: center;
  border: 1px solid transparent;

  margin: 0em 0.2em;
  padding: 0.1em 0.2em;
}

.agent-profile-aptitude-own {
  border: 1px solid #A39864;
}

.agent-profile-aptitude-cur {
  color: white;
  background: #8A8153;
  border: 1px solid #A39864;
}

.perk-btn {
  width: 6em;
  height: 6em;
  margin: 1.5em;
  background-color: black !important;
  border: 2px;
}

.waitingArea {
  width: 50%;
  height: 50%;

  display: flex;
  background: #403C2A;
  border: 2px solid #544F40;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.waitingArea>div {
  width: 100%;
  height: 90%;
  background: #17170F;
}

.star {
  background: #7D8A2C;
  border: 1px solid #67763B;

  margin-left: auto;
}

.squads {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-height: 100%;
  overflow: auto;
}

.overlay-content {
  background: #17170F;

  height: 60em;
}

.flex-agents-list .agent-profile-name {
  text-align: center;
  padding: 0em 1em;
}

.flex-agents-list-portrait {
  display: inline-block;
  width: 61px;
  height: 71px;
  flex: 0 0 auto !important;
}

.flex-agents-list-portrait>.portrait-frame {
  width: 61px;
  height: 71px;
}

.flex-agents-detail-portrait>.portrait-frame {
  width: 111px;
  height: 133px;
}

.tabbody {
  background: #585539;
  padding: 0.4em;
}

.flex-tabs {
  display: flex;
  flex-direction: row;
  font-weight: bold;
}

.flex-tab {
  width: 150px;

  text-align: center;

  white-space: nowrap;
  overflow: visible;

  text-transform: uppercase;
  background-color: var(--c-title01);
}

.flex-tab-selected {
  background-color: var(--c-fh1-highlight-fg);
}

.new-btn {
  width: 10em;
  height: 5em;
  margin: 1em;
}

.box-overlayhost {
  position: relative;
}

.box-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  color: var(--c-fh1-condition-nok);
  background: #44444477;
  text-shadow: -1px 0px black, 0px 1px black, 1px 0px black, 0px -1px black;

  pointer-events: none;
}

.box-overlay-pos {
  color: var(--c-fh1-condition-ok);
}

.box-overlay>h1 {
  font-size: 1.3em;
  font-weight: 900;
}

.box-overlay-selected {
  justify-content: flex-end;
  align-items: flex-end;

  color: red;
  font-size: 2em;
  background: none;
  font-weight: bold;
}

.box-overlay-selected::after {
  content: "SELECTED";
  padding: 0.0em 0.4em;
}

.top-paused {
  position: fixed;
  width: 100%;

  flex-direction: column;

  color: #ffffffaa;
  background: #44444488;
  text-align: center;

  z-index: 10;
}

.top-paused>h1 {
  color: red;
  font-size: 3em;
  font-weight: bold;
}

.stat-small {
  @apply border-collapse;
  text-align: center;
}

.missionItem-small {
  text-align: center;
}

.reward-small {
  text-align: center;
}

.flex-research-body {
  width: 100%;
  overflow: auto;
}

.flex-scroll-content {
  flex-grow: 1;

  margin-top: 0.5em;
  padding: 0.2em;

  max-height: 100%;
  overflow: auto;
}

.flex-plan-box {
  @apply grid03;

  margin: 0.4em 0.8em;
  padding: 0.4em 0em;
}

.flex-plan-box-header {
  margin: 0em 0.4em;
}

.flex-plan-box-body {
  margin: 0.2em 0.4em;
  padding: 0.4em 0.8em;
}

.flex-plan-box-body-modifiers {
  max-width: 740px;
  font-weight: 600;
  color: var(--c-fh1-wellseg-fg);
}

.flex-plan-preview {
  display: flex;
  flex-direction: row;
  margin: 0.4em 0.8em;
  padding: 0.5em 0em;

  border: 1px solid var(--c-fh1-wellseg-box-border);
}

.flex-plan-preview-column {
  flex: 1;
}

.flex-plan-preview-row {
  display: flex;
  flex-direction: row;
  margin: 0em 1em;
}

.flex-plan-preview-dash {
  display: flex;
  box-sizing: border-box;
  flex: 1;
  border: 1px solid #B8AB89;
  height: 1px;
  align-self: center;
  margin: 0em 1em;
}

.flex-plan-preview-value {
  display: flex;
  color: var(--c-fh1-wellseg-box-desc);
}

.flex-plan-btngroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-plan-btn-deployment {
  padding: 1em;
  margin: 1em;

  color: #FFE459;
  font-size: 1.2em;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-weight: 700;

  text-transform: uppercase;
}

.flex-missionitem {
  position: relative;

  border: 4px solid;
  color: var(--c-title00);
}

.flex-missionitem:hover {
  box-shadow: 5px 5px #888;
}

.flex-missionitem-selected {
  border-color: var(--c-fh1-highlight-fg);
}

@keyframes flex-missionitem-highlight-anim {
  from {
    box-shadow: 0 0 20px 5px #ccff00ff;
  }

  to {
    box-shadow: 0 0 20px 5px #ccff0000;
  }
}

.flex-missionitem-highlight {
  animation-name: flex-missionitem-highlight-anim;
  animation-duration: 3s;
  animation-timing-function: ease-in;
}

.flex-missionitem-milestone {
  border-color: var(--c-grid03);
}
.flex-missionitem-milestone>.flex-missionitem-title {
  background-color: var(--c-grid04);
}
.flex-missionitem-milestone>.flex-missionitem-info {
  background-color: var(--c-grid03);
}
.flex-missionitem-milestone>.flex-missionitem-footer {
  background-color: var(--c-grid03);
}

.flex-missionitem-title {
  display: flex;
  background-color: var(--c-grid01);
  padding: 0.2em 0.6em;
}

.flex-horizontal-info {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.flex-horizontal-info>div {
  flex: 1;
  margin: 0.2em 0.4em;
  padding: 0.2em 0.4em;
  border-left: 2px solid #534C35;
}

.flex-horizontal-info-key {
  color: var(--c-fh1-wellseg-box-desc);
  font-weight: 400;
}

.flex-horizontal-info-value {
  display: flex;
  font-size: 0.9em;
  font-weight: 600;
}

.flex-missionitem-footer {
  display: flex;
  flex-direction: row;
  background-color: var(--c-fh1-wellseg-box-bg);
}

.flex-missionitem-footer>div {
  padding: 0em 0.6em;
}

.flex-missionitem-expires {
  color: var(--c-fh1-expire-fg);
  background-color: var(--c-fh1-expire-bg);
  font-weight: 400;
  align-self: center;
}

.flex-missionitem-renown, .flex-missionitem-reward {
  background: var(--c-fh1-label-bg);
  margin: 0.2em 0.4em;
  width: 8em;

  display: flex;
}

.flex-missionitem-renown {
  margin-left: auto;
}

.flex-missionitem-footer-key {
  color: var(--c-yd-substrong-fg-desc);
}

.flex-missionitem-footer-value {
  color: var(--c-yd-substrong-fg);
  margin-left: auto;
  font-weight: 600;
}

.flex-list-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0.4em;
  overflow: hidden;
}

.flex-list-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  background-color: var(--c-grid00);
  border-color: var(--c-grid02);
  border-width: 0em 1em 1em 1em;
  padding: 0.4em 0em;
  overflow-y: scroll;
}

.flex-list-header {
  display: flex;
  color: var(--c-title00);
  background-color: var(--c-grid02);
  height: var(--height);
  align-items: center;
  justify-content: center;
  padding: 0em 1.4em;
  text-transform: capitalize;
}

.flex-list-row {
  display: flex;
  flex-direction: row;
  margin: 0.2em 0.6em;
  align-items: center;
  justify-content: space-evenly;

  color: var(--c-title00);
  background-color: var(--c-grid01);

  position: relative;
}

.flex-list-header>div:first-child,
.flex-list-row>div:first-child {
  border-left: none;
}

.flex-list-header>div,
.flex-list-row>div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid black;
}

.flex-list-sep {
  flex: 0 !important;
  border: 1px solid black;
  width: 0;
  height: 1em;
}

.district-row-etc {
  display: inline-block;
  width: 10em;
}

.district-btn-pos {
  color: #FFBE41;
}

.district-btn-neg {
  color: #FF7A41;
}

.row>div {
  margin-right: 0.5em;
}

.col {
  display: flex;
  flex-direction: column;
}

.col>div {
  margin-right: 0.5em;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--c-fh1-scrollbar-thumb);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--c-fh1-scrollbar-track);
  border-radius: 5px;
}

.overlay-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.overlay {
  position: absolute;
  top: 15%;
  left: 20%;
  width: 60%;
  height: 70%;

  padding: 2em 4em;
  border: 8px solid;
  border-color: var(--c-fh1-overlay-flex-border);
  color: var(--c-fh1-overlay-flex-fg);
  background-color: var(--c-fh1-overlay-flex-bg);

  display: flex;
  flex-direction: column;
}

.flex-recruitlist {
  display: flex;
  flex-direction: column;
}

.agent-profilerow {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #353029;
  margin: 0.2em 0.4em;
}

.agent-profilerow>img {
  height: 4em;
}

.agent-profilerow>div {
  display: flex;
  flex: 1;
  height: 4em;

  align-items: center;
  justify-content: center;
}

.agent-preview1 {
  display: flex;

  background: #403C2A;
  margin: 1em;

  flex-direction: column;
}

.agent-preview1>div {
  display: flex;
}

.agent-preview1-top {
  display: flex;
}

.agent-preview1-top p {
  font-size: 1.2em;
  font-weight: bold;
}

.agent-preview1-col {
  flex: 1;
}

.agent-trait {
  display: flex;
  flex-direction: row;
  margin: 0.5em;
}

.agent-preview1-title {
  width: 8em;
}

.agent-preview1-num {
  flex: 3;
  background: #312E20;
  padding: 0.3em;
}

.red {
  color: red;
}

.yellow {
  color: #FDC536;
}

.agent-preview1-aptitude {
  background: #312E20;
  padding: 0.5em;
}


.list-portrait {
  width: 61px;
  height: 71px;
}

.list-portrait>.portrait-frame {
  width: 61px;
  height: 71px;
}

.flex-title {
  text-transform: uppercase;
  font-size: 1.5em;
  margin: 0.2em 0.6em;

  font-weight: 900;
}

.agent-preview1-box {
  background: #312E20;

  margin: 1em;
  padding: 1em
}

.rate-label {
  font-weight: 700;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.item-box2 {
  display: flex;
  width: 200px;
  height: 160px;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-shrink: 0;

  border: 3px solid var(--c-fh1-item-panel-border);
  background-color: var(--c-fh1-item-panel-bg);
  color: var(--c-fh1-item-panel-fg);
}

.item-box2:hover {
  border: 3px solid var(--c-fh1-item-panel-hover-border);
}

.item-box2>hr {
  width: 190px;
  align-self: center;
  border: none;
  border-top: 1px solid var(--c-fh1-item-panel-separator);
}

.item-box2-header {
  display: flex;
  padding: 4px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  gap: 4px;

  background-color: var(--c-fh1-item-panel-title-bg);
}

.item-box2-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.label-rate.with-style {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  padding: 2px 3px;
  align-items: center;
  gap: 1px;
  margin-right: 4px;

  text-overflow: ellipsis;
}

.label-rate.with-style.rate-1 {
  background-color: var(--c-fh1-item-label-tier1-bg);
  color: var(--c-fh1-item-label-tier1-fg);
}

.label-rate.with-style.rate-2 {
  background-color: var(--c-fh1-item-label-tier2-bg);
  color: var(--c-fh1-item-label-tier2-fg);
}

.label-rate.with-style.rate-3 {
  background-color: var(--c-fh1-item-label-tier3-bg);
  color: var(--c-fh1-item-label-tier3-fg);
}

.label-rate.with-style.rate-4 {
  background-color: var(--c-fh1-item-label-tier4-bg);
  color: var(--c-fh1-item-label-tier4-fg);
}

.label-rate.with-style.rate-5 {
  background-color: var(--c-fh1-item-label-tier5-bg);
  color: var(--c-fh1-item-label-tier5-fg);
}

.label-rate-star {
  margin-right: 0.1em;
}

.item-box2-footer {
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.listing-item-desc {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.item-equip-candidate, .agent-equip-candidate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

div.flex-2 {
  flex: 2;
}

.icon-wrapper {
  position: relative;
}

.badge {
  position: absolute;
  top: -3px;
  right: -10px;
  background-color: green;
  color: white;
  font-size: 12px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.badge-new {
  @apply badge;
  /* right: 15px; */
  background-color: #FE8469;
}

.squad-tab .badge,
.squad-tab .badge-new {
  top: -8px;
  right: 4px;
  zoom: 0.8;
}

.overlay-modifiers {
  position: absolute;
  top: 2px;
  left: 220px;
}

.agent-selected-num {
  border: 1px solid #454134;
  margin: 0.2em;
  padding: 0.1em 0.2em;
}

.mission-result {
  @apply flex-scroll-content;
  background-color: inherit;

  display: flex;
  flex-direction: column;
}

.mission-result-reward-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  height: 210px;
  gap: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 2em;
  height: 2em;
  background-color: #6F2828;
  color: rgb(235, 179, 179);
}

.v-center {
  align-self: center;
}

.stat-low {
  color: #c6bf9e;
}

.stat-mid {
  color: #EF5350;
}

.stat-high {
  color: #B71C1C;
}

.agent-avail {
  color: blue;
}

.agent-unavail {
  color: red;
}

.training-item-sent {
  display: flex;
  font-weight: normal;
}

.training-item-sent h1 {
  font-size: 1.2em;
}

.training-item-sent .training-item-sent-left {
  flex-grow: 1;
}

.training-item-sent .training-item-sent-right {
  flex-grow: 0;
}

.flex-expectation-label-0 {
  color: #A62828;
}

.flex-expectation-label-1 {
  color: #933328;
}

.flex-expectation-label-2 {
  color: #7F3D27;
}

.flex-expectation-label-3 {
  color: #6C4827;
}

.flex-expectation-label-4 {
  color: #595326;
}

.flex-expectation-label-5 {
  color: #455D26;
}

.flex-expectation-label-6 {
  color: #326825;
}

.flex-stamina-label-0 {
  color: #A62828;
}

.flex-stamina-label-1 {
  color: #893827;
}

.flex-stamina-label-2 {
  color: #6C4827;
}

.flex-stamina-label-3 {
  color: #4F5826;
}

.flex-stamina-label-4 {
  color: #326825;
}

.contract-agent-perkpoint-indicator {
  color: var(--c-fh1-condition-nok);
  font-weight: bold;
  font-style: italic;
}


@keyframes spinner-spins {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(3600deg);
  }

}

.spinner-x1 {
  animation-duration: 60s;
}

.spinner-x2 {
  animation-duration: 30s;
}

.spinner-x3 {
  animation-duration: 20s;
}

.spinner-x4 {
  animation-duration: 15s;
}

.spinner-x5 {
  animation-duration: 12s;
}

.spinner-paused {
  animation-play-state: paused;
}

.flex-map-root {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.flex-map-right {
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;
}



.flex-cond-ok:before {
  content: '[v] ';
}

.flex-cond-ok {
  color: var(--c-fh1-condition-ok);
}

.checklist.box .flex-cond-ok {
  color: var(--c-fh1-condition-box-ok);
}

.flex-cond-nok:before {
  content: '[x] ';
}

.flex-cond-nok {
  color: var(--c-fh1-condition-nok);
}

.checklist.box .flex-cond-nok {
  color: var(--c-fh1-condition-box-nok);
}

.flex-simview {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.flex-simview-canvas {
  background: rgb(20, 20, 24);
}

.flex-simview h1 {
  color: white;
}

.flexdebug {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
}

.flexmapoptions {
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 0;
  bottom: 0;
}

.flex-worldcenter {
  /* ??? */
  overflow-y: auto;
}

.flex-worldcenter-readonly {
  @apply flex-worldcenter;
  overflow-y: hidden;
}

.flex-worldcenter-facility {
  margin-bottom: 0.5em;
  padding: 0.4em 0.8em;
  color: var(--c-fh1-wellseg-box-fg);
  background-color: var(--c-fh1-wellseg-box-bg);
  border: 2px solid var(--c-fh1-wellseg-box-border);
}

.flex-worldcenter-facility-descr {
  color: var(--c-fh1-wellseg-box-desc);
}

.flex-worldcenter-facility-alert {
  color: var(--c-fh1-wellseg-box-alert);
}

.flex-worldcenter-facility-avail {
  color: var(--c-fh1-highlight-fg);
}

.flexoverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  pointer-events: none;
  overflow: hidden;
}

.flexoverlay-item {
  position: absolute;
  color: var(--c-fh1-flexoverlay-fg);
  background-color: var(--c-fh1-flexoverlay-bg);
  border: 2px solid var(--c-fh1-flexoverlay-border);
  padding: 2px;

  pointer-events: auto;

  display: flex;
  flex-direction: column;
}

.flexoverlay-item-readonly {
  pointer-events: none;
}

.flexoverlay-item-nostyle {
  color: inherit;
  border: inherit;
  background-color: inherit;
  pointer-events: none;
}

.flexoverlay-item:hover {
  z-index: 2;
}

.flexoverlay-item>.title {
  background-color: var(--c-fh1-flexoverlay-title-bg);
  color: var(--c-fh1-flexoverlay-title-fg);
  text-align: center;
  font-size: 14px;
}

.flexoverlay-item>.flexoverlay-forces {
  font-size: 13px;

  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: max-content;
  gap: 2px;
  padding: 4px;
}

.flexoverlay-item>.flexoverlay-forces>.label {
  color: var(--c-fh1-flexoverlay-box-fg);
  grid-column: 1;

  display: flex;
  align-items: center;
}

.flexoverlay-item>.flexoverlay-forces>.content {
  grid-column: 2;

  display: flex;
  justify-content: center;
}

.flexoverlay-item>.flexoverlay-forces .value {
  color: var(--c-fh1-flexoverlay-box-fg);
}

.flexoverlay-item>.flexoverlay-forces .value.important {
  color: var(--c-fh1-flexoverlay-fg-important);
}

.flexoverlay-agent, .flexoverlay-upgrading {
  font-size: 12px;
  padding: 4px 6px;
  gap: 3px;
}

.flexoverlay-agenticon-root {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 6px;
  margin: 0px 6px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  background-color: var(--c-fh1-flexoverlay-box-bg);
  color: var(--c-fh1-flexoverlay-box-fg);
  width: 270px;
  overflow-x: auto;
}

.flexoverlay-item::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--c-fh1-flexoverlay-scrollbar-thumb);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--c-fh1-flexoverlay-scrollbar-track);
  border-radius: 5px;
}

.flexoverlay-agenticon {
  position: relative;
  padding: 0.1em 0.4em;
}

.flexoverlay-agenticon-portrait {
  width: 59px;
  height: 64px;
}

.flexoverlay-agenticon-portrait>.portrait-frame {
  width: 59px;
  height: 64px;
}

.flexoverlay-agenticon-firearm {
  position: absolute;
  top: 0;
  text-transform: uppercase;
  color: var(--c-fh1-highlight-fg);
  text-shadow: 1px 1px var(--c-title00);

  font-weight: 900;
}

.flexoverlay-agenticon-label {
  width: 48px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flexoverlay-upgrading-desc {
  color: var(--c-fh1-flexoverlay-progress-fg);
}

.flexoverlay-upgrading-desc>span, .flexoverlay-market-desc, .flexoverlay-recruit-desc, .flexoverlay-work-desc {
  color: var(--c-fh1-flexoverlay-fg);
  font-size: 12px;
  padding: 3px 6px;
}

.flexoverlay-upgradable-desc, .flexoverlay-work-item-desc {
  color: var(--c-fh1-flexoverlay-progress-desc-fg);
  font-size: 12px;
  padding: 3px 6px;
}

.flexoverlay-listcontainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 4px;
}

.flexoverlay-market, .flexoverlay-recruit {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 3fr;
  padding: 4px 6px;
  gap: 2px 0px;

  width: 95%;
  min-width: 278px;
  align-items: center;
  align-self: center;

  background-color: var(--c-fh1-flexoverlay-box-bg);
  border: none;
  color: var(--c-fh1-flexoverlay-box-fg);
  font-size: 12px;
}

.flexoverlay-recruit>span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexoverlay-recruit>span.name {
  justify-content: start;
  grid-column: span 5;
}

.flexoverlay-recruit>span.power {
  white-space: nowrap;
}

.flexoverlay-recruit>.fh1-button {
  grid-row: span 2;
  color: var(--c-fh1-button-general-fg);
  background-color: var(--c-fh1-button-general-bg);
  border: 1px solid var(--c-fh1-button-general-border);
  margin:
}

.flexoverlay-recruit>.power, .flexoverlay-recruit>.potential, .flexoverlay-recruit>.growthcap, .flexoverlay-recruit>.physicalcap {
  color: var(--c-fh1-flexoverlay-box-desc-fg);
}

.flexoverlay-recruit>.power.desc {
  justify-content: start;
}

.flexoverlay-work {
  background-color: var(--c-fh1-flexoverlay-box-bg);
  border: none;
  color: var(--c-fh1-flexoverlay-box-fg);
  font-size: 12px;

  width: 95%;
  align-self: center;
}

.flex-agents-figmaroot {
  z-index: 2;

  margin-top: 5em;
  overflow: hidden !important;
  max-width: 1525px;
  max-height: 950px;
}

.flex-agents-figmaroot div {
  display: flex;
}

.flex-agents-figmaroot .filter {
  position: absolute;
  top: 0;
  left: 80px;
  color: #684819;
  font-size: 15px;
  font-weight: 700;
}

.flex-agents-figmaroot .filter .current {
  position: absolute;
  top: 0;
  left: 0;
  width: 388px;
  padding: 2px;
}

.flex-agents-figmaroot .filter .control {
  position: absolute;
  top: 0;
  left: 400px;
  width: 240px;
  padding: 2px;
}

.overlay-agentpreview {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
}

.overlay-agentpreview>div {
  position: relative;
  top: inherit;
  left: inherit;
}

.flex-notification-root {
  color: var(--c-title00);
}

.flex-notification-body {
  display: flex;
  height: 13em;
  flex-direction: column;
  overflow-y: auto;
}

.flex-info-row-push {
  margin-left: auto;
}

.flex-info-row {
  margin: 0.1em 0.2em;
}

.flex-info-row-info {
  color: var(--c-fh1-info-fg);
}

.flex-info-row-warn {
  color: var(--c-fh1-warn-fg);
}

.flex-info-row-warn-title {
  animation: warn-blink 1s infinite;
}

@keyframes warn-blink {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

.flex-info-row-notice {
  color: var(--c-fh1-notice-fg);
}

.flex-info-root {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 560px;
  color: var(--c-title00);
}

.flex-info-title {
  font-weight: bold;
  font-size: 1.2em;
}

.flex-info-body {
  display: flex;
  height: 13em;
  flex-direction: column;
  overflow-y: auto;
  background-color: #77777799;
  padding: 0.2em 0.4em;
  margin: 0em 1em 1em 0em;
}

.flexoverlay-forces-owner {
  color: var(--c-fh1-forces-owner-fg);
}

.flexoverlay-force-wrapper {
  display: grid;
  grid-template-columns: max-content;
  align-items: center;
  row-gap: 2px;
  margin: 5px;
}

.flexoverlay-force-wrapper>.fh1-progress {
  grid-column: 2;
}

.flexoverlay-force-status {
  width: 100%;
  text-align: center;
  background-color: var(--c-fh1-panel-small-title-bg);
  color: var(--c-fh1-panel-small-title-fg);
}

.flexoverlay-force-label {
  text-align: right;
  padding-right: 10px;
  grid-column: 1;
}

.flex-config-title {
  font-weight: bold;
  font-size: 1.2em;
}

.fh1-flatwell {
  overflow-x: hidden;
  overflow-y: scroll;
}
.fh1-flatwell>*:not(:last-child) {
  margin-bottom: 8px;
}

.flexleftbtn-bubble {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--c-fh1-highlight-fg);
  font-size: 1.4em;
  text-shadow: 2px 2px black;

  font-weight: 900;

  /* 임시로 알림 숫자 눈에 잘 띄도록 */
  text-align: center;
  vertical-align: middle;
  width: 1.4em;
  height: 1.4em;
  background: gray;
  box-shadow: 2px 2px white;
}

.overlay-root-shrink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay-flex {
  display: flex;

  border: 8px solid;
  border-color: var(--c-fh1-overlay-flex-border);
  background: var(--c-fh1-overlay-flex-bg);

  padding: 2em 4em;
  flex-direction: column;

  align-items: center;
  color: var(--c-fh1-overlay-flex-fg);

  max-height: 90vh;
}

.overlay-flex-quest {
  display: flex;

  outline: 8px solid;
  outline-color: var(--c-fh1-overlay-flex-border);
  background: var(--c-fh1-overlay-flex-bg);

  flex-direction: column;

  align-items: center;
  justify-content: space-between;
  color: var(--c-fh1-overlay-flex-fg);

  width: 872px;
  height: 450px;
}

.overlay-flex-quest>.overlay-quest-header {
  background-color: var(--c-fh1-panel-title-bg);
  color: var(--c-fh1-panel-title-fg);

  display: flex;
  height: 28px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.overlay-flex-quest>.mission-warning-overlay-title {
  padding: 10px;
  margin: 0px;

  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.overlay-flex-quest>hr {
  border: 2px solid var(--c-fh1-overlay-flex-separator);
  width: 852px;
}

.overlay-flex-quest .checklist.box {
  width: 852px;
  height: 100px;
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  overflow-y: auto;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.overlay-flex-quest.special {
  background: var(--c-fh1-overlay-flex-special-bg);
  outline-color: var(--c-fh1-overlay-flex-special-border);
  color: var(--c-fh1-overlay-flex-special-fg);
}

.overlay-flex-quest.special>.overlay-quest-header {
  background-color: var(--c-fh1-overlay-flex-special-title-bg);
  color: var(--c-fh1-overlay-flex-special-title-fg);
}

.overlay-flex-quest.special .overlay-quest-body {
  color: var(--c-fh1-overlay-flex-special-fg-desc);
}

.overlay-flex-quest.special>hr {
  border: 2px solid var(--c-fh1-overlay-flex-special-border);
}

.overlay-flex-quest button.fh1-button.fh1-button-tiny {
  display: flex;
  width: 852px;
  padding: 10px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.overlay-flex button {
  margin: 0.2em 0.4em;
  padding: 0.8em 1.6em;
}

.overlay-flex-btngroup {
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  margin-bottom: 1em;
}

.mission-warning-overlay-title {
  font-size: 1.3em;
  font-weight: bolder;

  margin-bottom: 1em;
}

.mission-warning-overlay-subtitle {
  font-size: 1.2em;
  font-weight: bolder;

  margin-top: 1em;
}

.mission-warning-overlay-body {
  overflow-y: auto;
}

.mission-warning-overlay-body {
  font-weight: bold;
}

.mission-warning-overlay-body-important {
  font-weight: bold;
  color: var(--c-red00);
}

.mission-start-tip {
  max-width: 400px;
}

.mission-warning-overlay-btn {
  margin: 0.2em 0.4em;
  padding: 0.8em 1.6em;
}

button.mission-warning-overlay-btn2 {
  color: var(--c-grid00);
  background-color: transparent;
  margin: 0;
  padding: 2px;
}

.mission-warning-overlay-btn2-selected {
  background-color: var(--c-fh1-highlight-fg) !important;
}

.overlay-flex>.flex-missionitem,
.overlay-flex>.agent-profile {
  align-self: normal;
}

.mission-result-title {
  font-size: 1.4em;
  font-weight: bolder;
  align-self: center;
}

.mission-result-subtitle {
  margin-top: 0.4em;
  font-size: 1.2em;
  font-weight: bold;
}

.mission-result-agent-td {
  width: min-content;
}

.mission-result-tab {}

.mission-result-tab-selected {
  color: var(--c-fh1-highlight-fg) !important;
}

.checklist.box {
  display: flex;
  flex-direction: column;
}

.journal-item-paused {
  @apply flex-info-row-warn;
  @apply flex-info-row-warn-title;
}
.flex-sep {
  margin: 1em 0em;
  border: 2px dashed black;
}
.flex-clipboard {
  margin: 0.2em 0.4em;
}
.overlay-quest-body {
  width: 852px;
  height: 145px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  white-space: pre;
  overflow-y: auto;
  padding: 0px 10px;
}

.overlay-quest-expire {
  padding: 5px 10px;
  background-color: var(--c-cd-subtitle-bg);
  color: var(--c-cd-subtitle-fg);
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: stretch;
  gap: 10px;

  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.overlay-flex-quest .overlay-quest-expire {
  width: 852px;
}

/* anima override */
.contract-compact div.wrapper {
  position: inherit;
}
.CIPO-cell {
}

.market-addon {
  display: grid;
  margin-left: 8px;
  margin-right: 8px;
}

.market-item-detail {
  position: fixed;
  left: 500px;
  background-color: var(--c-popup01);
  border: 5px solid;
  /* --c-popup00 */
  border-color: #e1995e;
  z-index: 1;
  display: flex;
  text-align: center;
}

.market-item-detail-right{
  margin-left: 10px;
}

.inventory-button{
  margin-right: 5px;
}

.inventory-item-notice{
  color: var(--c-fh1-condition-nok);
  font-weight: bold;
}

button.agent-equip-button {
  margin: 0;
  padding: 2px;
}

.item-arrow-higher {
  color: var(--c-fh1-better);
}

.item-arrow-lower {
  color: var(--c-fh1-worse);
}

.agent-critical {
  color: var(--c-fh1-warn-fg);
}

button.fh1-button.agent-contract-cancellation {
  background-color: var(--c-fh1-button-destructive-bg);
  color: var(--c-fh1-button-destructive-fg);
  border: 1px solid var(--c-fh1-button-destructive-border);
}

button.fh1-button.agent-contract-cancellation:disabled {
  background-color: var(--c-fh1-button-disabled-bg);
  color: var(--c-fh1-button-disabled-fg);
  border: 1px solid var(--c-fh1-button-disabled-border);
}

.report-quest {
  background-color: var(--c-fh1-overlay-flex-special-bg);
  color: var(--c-fh1-overlay-flex-special-fg);

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  align-self: stretch;
}

.report-quest-header {
  background-color: var(--c-fh1-overlay-flex-special-title-bg);
  color: var(--c-fh1-overlay-flex-special-title-fg);

  display: flex;
  height: 36px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  align-self: stretch;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.report-quote {
  color: var(--c-fh1-overlay-flex-special-fg-desc);
  font-style: italic;

  display: flex;
  height: 60px;
  padding: 0px 15px;
  align-items: flex-start;
  align-self: stretch;

  overflow-y: scroll;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  white-space: pre-line;
}

.report-quest>.checklist.box {
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100px;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.flexoverlay-top {
  z-index: 20;
}
.flexoverlay-btn-expand {
  animation: rainbowBackground 5s linear infinite;
  mix-blend-mode: difference;
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

@keyframes rainbowBackground {
  0%, 100% { background-color: red; }
  14% { background-color: orange; }
  28% { background-color: yellow; }
  42% { background-color: green; }
  57% { background-color: blue; }
  71% { background-color: indigo; }
  85% { background-color: violet; }
}
