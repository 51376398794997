.button-radio-onoff {
  background-image: url(../../../static/anima/img/property-1-default-1.svg);
  background-size: 100% 100%;
  height: 20px;
  width: 20px;
}

.button-radio-onoff .button-radio {
  height: 20px;
}

.button-radio-onoff .foo {
  height: 20px;
  width: 20px;
}

.button-radio-onoff .bar {
  background-image: url(../../../static/anima/img/vector-23.svg);
  background-size: 100% 100%;
  height: 18px;
  left: 1px;
  opacity: 0.9;
  position: relative;
  top: 1px;
  width: 18px;
}

.button-radio-onoff .rounded-rectangle-wrapper {
  background-image: url(../../../static/anima/img/vector-24.svg);
  background-size: 100% 100%;
  height: 18px;
}

.button-radio-onoff .rounded-rectangle {
  background-color: #38f76e;
  border-radius: 3px;
  height: 12px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 12px;
}

.button-radio-onoff .unchecked {
  opacity: 0.01;
}
