.agent-perk {
  height: 907px;
  position: relative;
  width: 751px;
}

.agent-perk .overlap-14 {
  height: 707px;
  left: 17px;
  position: absolute;
  top: 84px;
  width: 720px;
}

.agent-perk .bg-4 {
  height: 703px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 708px;
}

.agent-perk .rectangle-17 {
  background-color: #f3c72b;
  height: 703px;
  left: 52px;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 656px;
}

.agent-perk .rectangle-18 {
  background-color: #f3c72b;
  height: 675px;
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 28px;
  width: 44px;
}

.agent-perk .scroll-area {
  height: 678px;
  left: 0;
  overflow-y: /* PMC */ scroll; overflow-x: hidden;
  position: absolute;
  top: 29px;
  width: 720px;
}

.agent-perk .perk-trees-container-instance {
  left: 54px !important;
  position: absolute !important;
  top: 2px !important;
}

.agent-perk .perk-levels-container {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.agent-perk .perk-level-trim {
  height: 707px;
  left: 0;
  position: absolute;
  top: 0;
  width: 52px;
}

.agent-perk .rectangle-19 {
  background-color: #c8a857;
  height: 707px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.agent-perk .rectangle-20 {
  background-color: #c8a857;
  height: 707px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 1px;
}

.agent-perk .fixed-header {
  background-color: #c8a857;
  height: 28px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 52px;
}

.agent-perk .perklevel-wrapper {
  background-color: #f8df89;
  height: 27px;
  left: 1px;
  position: relative;
  width: 50px;
}

.agent-perk .perklevel {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 13px;
  font-weight: 500;
  height: 19px;
  left: 3px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 4px;
  width: 44px;
}

.agent-perk .overlap-15 {
  height: 57px;
  left: 17px;
  position: absolute;
  top: 27px;
  width: 714px;
}

.agent-perk .div-5 {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 714px;
}

.agent-perk .overlap-16 {
  height: 57px;
  position: relative;
}

.agent-perk .rectangle-21 {
  background-color: #f2d87e;
  height: 51px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 708px;
}

.agent-perk .vector-9 {
  height: 6px;
  left: 708px;
  position: absolute;
  top: 51px;
  width: 6px;
}

.agent-perk .vector-10 {
  height: 6px;
  left: 0;
  position: absolute;
  top: 51px;
  width: 6px;
}

.agent-perk .vector-11 {
  height: 6px;
  left: 708px;
  position: absolute;
  top: 0;
  width: 6px;
}

.agent-perk .vector-12 {
  height: 6px;
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}

.agent-perk .vector-13 {
  height: 1px;
  left: 6px;
  object-fit: cover;
  position: absolute;
  top: 56px;
  width: 702px;
}

.agent-perk .vector-14 {
  height: 1px;
  left: 6px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 702px;
}

.agent-perk .head-info {
  height: 26px;
  left: 18px;
  position: absolute;
  top: 0;
  width: 714px;
}

.agent-perk .headinfo {
  align-items: center;
  display: inline-flex;

  left: 9px;
  position: relative;
}

.agent-perk .div-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;

  height: 26px;
  position: relative;
}

.agent-perk .total {
  color: #684819;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.agent-perk .text-wrapper-9 {
  color: #684818;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.agent-perk .line-2 {
  background-image: url(../../../static/anima/img/v-34-1debf-5.svg);
  background-size: 100% 100%;
  height: 26px;
  position: relative;
  width: 1px;
}

.agent-perk .frame-38 {
  height: 22px;
  margin-left: -0.12px;
  position: relative;
  width: 21.12px;
}

.agent-perk .design-component-instance-node-2 {
  position: relative !important;
}

.agent-perk .PGB-con-c-instance {
  left: 3px !important;
  position: absolute !important;
  top: 5px !important;
}
.agent-perk .div-6>* { margin-left: 7px; } .agent-perk .div-6>*:first-child { margin-left: 0; }
.agent-perk .headinfo>* { margin-left: 16px; } .agent-perk .headinfo>*:first-child { margin-left: 0; }
