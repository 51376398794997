html,
body,
#root {
  height: 100%;
}

.department-root {
  width: 70vw;
  max-height: 80vh;
  overflow-y: auto;
}

/* gameface 버그 우회 우회 */
/*
.department-root tr,
.department-overlay-root tr
{
  display: flex;
}
*/

.department-root td,
.department-overlay-root td
{
  text-align: center;
}

.flex-department-preview {
  display: flex;
  flex-direction: row;
}

.department-preview {
  display: flex;
  flex-direction: row;
}

.department-emptyslot {
  color: var(--c-title01);
}

.flex-department-left {}

.flex-department-middle {
  font-size: 2em;
  margin: 0.4em;
  align-self: center;
}

.flex-department-right {}


.flex-department-logs {
  height: 200px;
  overflow: auto;
}

.department-overlay-root {
  color: #C6BF9E;
}

.flex-department-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 1em;
}

.flex-department-compare td,
.flex-department-compare th {
  width: 100px;
}

.flex-department-subtitle {
  font-size: 1.2em;
  font-weight: 900;
}

.flex-department-foldbtn {
  display: inline-block;
}

.flex-department-warn {
  color: var(--c-fh1-highlight-fg);
}

.flex-department-popup-title {
  @apply flex-department-subtitle;

  align-self: center;
}

.flex-department-popup-subtitle {
  font-weight: 900;
  align-self: center;
}

.flex-department-diff-warn {
  font-weight: 900;
  color: var(--c-fh1-highlight-fg);
}

.overlay-flex .department-row-button {
  margin: 0em 0em;
  padding: 0em 1em;
}

.overlay-flex-departmentStatDiff {
  display: flex;
  flex-direction: column;

  max-height: 80vh;
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;
}

.overlay-flex-departmentStatDiff::-webkit-scrollbar {
  display: none;
}

.department-staffrow-highlighted {
  color: var(--c-fh1-highlight-fg);
}

.dt-short {
  width: 100px;
}
.dt-long {
  width: 300px;
}
.dt-verylong {
  width: 400px;
}

.box p {
  display: block;
}