.editor-root {
}

.editor-root textarea {
  border: 1px solid gray;
}
.editor-btn-eye-off {
  color: gray;
}

.editor-layers {
  float: right;
}
