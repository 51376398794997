.agent-contract {
  height: 801px;
  position: relative;
  width: 725px;
}

.agent-contract .theredbutton-SI-instance {
  left: 491px !important;
  position: absolute !important;
  top: 746px !important;
}

.agent-contract .lines {
  height: 6px;
  left: 20px;
  position: absolute;
  top: 728px;
  width: 705px;
}

.agent-contract .agentcard-item-2 {
  align-items: center;
  display: flex;

  justify-content: center;
  left: 370px;
  padding: 1px 0px;
  position: absolute;
  top: 704px;
  width: 347px;
}

.agent-contract .title-20 {
  color: #8f3420;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.agent-contract .dot-line-2 {
  flex: 1 1 0;
  flex-grow: 1;
  height: 1.5px;
  position: relative;
}

.agent-contract .value-2 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;

  justify-content: flex-end;
  padding: 0px 2px;
  position: relative;
}

.agent-contract .bulletproof-bold {
  color: #8f3420;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.agent-contract .overlap-10 {
  height: 575px;
  left: 20px;
  position: absolute;
  top: 16px;
  width: 705px;
}

.agent-contract .contract-option-wrapper {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;
  flex-direction: column;

  height: 302px;
  left: 0;
  position: absolute;
  top: 273px;
}

.agent-contract .contract-summary {
  height: 333px;
  left: 0;
  position: absolute;
  top: 0;
  width: 705px;
}

.agent-contract .overlap-11 {
  height: 255px;
  position: relative;
  top: 2px;
}

.agent-contract .table-titles {
  height: 255px;
  left: 0;
  position: absolute;
  top: 0;
  width: 705px;
}

.agent-contract .overlap-group-10 {
  height: 236px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 705px;
}

.agent-contract .vector-6 {
  height: 207px;
  left: 186px;
  position: absolute;
  top: 29px;
  width: 2px;
}

.agent-contract .vector-7 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 28px;
  width: 705px;
}

.agent-contract .rectangle-2 {
  background-color: #906a05;
  height: 29px;
  left: 0;
  position: absolute;
  top: 0;
  width: 705px;
}

.agent-contract .rectangle-good {
  background-color: #906a05;
  height: 3px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 705px;
}

.agent-contract .overlap-12 {
  background-color: #eace77;
  height: 24px;
  left: 0;
  position: absolute;
  top: 243px;
  width: 705px;
}

.agent-contract .title-21 {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 322px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 3px;
  white-space: nowrap;
}

.agent-contract .title-22 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 210px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 45px;
  white-space: nowrap;
}

.agent-contract .title-23 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 210px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 86px;
  white-space: nowrap;
}

.agent-contract .title-24 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 210px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 128px;
  white-space: nowrap;
}

.agent-contract .title-25 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 210px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 170px;
  white-space: nowrap;
}

.agent-contract .title-26 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 210px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 212px;
  white-space: nowrap;
}

.agent-contract .title-27 {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 46px;
  white-space: nowrap;
}

.agent-contract .title-28 {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 86px;
  white-space: nowrap;
}

.agent-contract .title-29 {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 130px;
  white-space: nowrap;
}

.agent-contract .title-30 {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 171px;
  white-space: nowrap;
}

.agent-contract .title-31 {
  color: #906a05;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 212px;
  white-space: nowrap;
}

.agent-contract .title-32 {
  color: #ffe99d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 244px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.agent-contract .lines-hor {
  height: 170px;
  left: 0;
  position: absolute;
  top: 73px;
  width: 705px;
}
.agent-contract .contract-option-wrapper>* { margin-top: 7px; } .agent-contract .contract-option-wrapper>*:first-child { margin-top: 0; }
.agent-contract .agentcard-item-2>* { margin-left: 6px; } .agent-contract .agentcard-item-2>*:first-child { margin-left: 0; }
.agent-contract .value-2>* { margin-left: 2px; } .agent-contract .value-2>*:first-child { margin-left: 0; }
