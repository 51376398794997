.PGB-tooltip-content {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;
  flex-direction: column;

  position: relative;
  width: 350px;
}

.PGB-tooltip-content .pt-title {
  color: #baf1dd;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 22px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 283px;
}

.PGB-tooltip-content .pt-desc {
  color: #82ccb6;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  position: relative;
  width: 350px;
}
.PGB-tooltip-content>* { margin-top: 8px; } .PGB-tooltip-content>*:first-child { margin-top: 0; }
