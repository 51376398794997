.stat-grade {
  align-items: center;
  display: flex;
  flex-direction: column;

  position: relative;
  width: 101px;
}

.stat-grade .statgrade {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;

  position: relative;
}

.stat-grade .statname {
  color: #7d490d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.stat-grade .div-2 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.stat-grade .g-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.stat-grade .g {
  align-self: stretch;
  color: #3d1e0d;
  font-family: "Freshman", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 22px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
}

.stat-grade .frame-25 {
  align-self: stretch;
  background-color: #3d1e0d;
  height: 1px;
  position: relative;
  width: 100%;
}
.stat-grade>* { margin-top: 11px; } .stat-grade>*:first-child { margin-top: 0; }
.stat-grade .statgrade>* { margin-top: 11px; } .stat-grade .statgrade>*:first-child { margin-top: 0; }
