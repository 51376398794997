.PGB-con-c {
  align-items: center;
  display: flex;
  height: 47px;
  position: relative;
  width: 708px;
}

.PGB-con-c .PGB-button-instance {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  position: relative !important;
  width: unset !important;
}

.PGB-con-c .PGB-button-2 {
  width: 233px !important;
}

.PGB-con-c .PGB-button-3 {
  width: 180px !important;
}

.PGB-con-c .PGB-splitter-instance {
  position: relative !important;
}

.PGB-con-c .PGB-button-4 {
  margin-left: unset !important;
}

.PGB-con-c .PGB-button-5 {
  margin-right: unset !important;
}

.PGB-con-c .PGB-button-c {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  margin-right: -0.42px !important;
  position: relative !important;
  width: unset !important;
}
