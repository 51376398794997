:root {
  --sim-overlay-inner-width: 360px;
  --sim-overlay-canvas-height: 360px;
  --sim-overlay-entity-img-width-height: 68px;
  --sim-overlay-entity-img-hover-width: calc((112 / 68) * var(--sim-overlay-entity-img-width-height));

  --sim-overlay-actual-width: calc(
    var(--sim-overlay-inner-width)
     + var(--sim-overlay-entity-img-hover-width)
      - var(--sim-overlay-entity-img-width-height)
      );

  --sim-overlay-entity-action-indicator-width: 41px;
  --sim-overlay-entity-action-indicator-height: 58px;
}

.sim-overlay {
  position: fixed;
  right: 0em;
  bottom: 0em;

  width: var(--sim-overlay-actual-width);
  z-index: 100;

  font-family: 'The Jamsil OTF';
}

.sim-overlay-debug {
  position: fixed;
  right: calc(2em + var(--sim-overlay-inner-width));
  bottom: 1em;
  padding: 0px 2px;
  border: 2px solid var(--c-highlight02);

  width: var(--sim-overlay-inner-width);
  background-color: var(--c-grid01);
}


.sim-overlay button {
  color: var(--c-grid01);
  background-color: var(--c-title00);
}

.sim-overlay button:hover {
  background-color: var(--c-highlight01);
}

.sim-overlay button.selected {
  background-color: var(--c-highlight00);
}

.sim-overlay-div-btn {
  background: inherit;
  text-align: center;
}

.sim-overlay-div-btn > img {
  transform:scale(1.15, 1.15);
}

.sim-overlay-div-btn:hover:not(.selected) > img {
  transform:scale(1.10, 1.10);
}

.sim-overlay-div-btn.selected > img {
  transform: scale(1.10, 1.10) translate(0, 3px);
}

.sim-overlay-group {
  position:absolute;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  width: var(--sim-overlay-inner-width);

  background-color: var(--c-grid01);
  color: white;

  overflow-y: auto;
  overflow: visible;

  z-index: 0;
}

.sim-overlay-group hr {
  border-top: 1px solid gray;
  margin: 4px;
}


.sim-overlay-canvas-container {
  width: var(--sim-overlay-inner-width);
  height: var(--sim-overlay-canvas-height);

  background-color: #00ff0099;
}

.sim-overlay-change-group-btn {
  width: 30px;
    height: 30px;
    position: absolute;
    top: 377px;
    left: 145px;
    /* 버튼 위치 구분을 위한 임시 배경 */
    background: #000000aa;
}

.sim-overlay-group-tabs {
  display:flex;
}

.sim-overlay-group-tab {
  width:fit-content;
  margin-right: 10px;
}

.sim-overlay-journals {
  overflow-y: scroll;
  height: 160px;
}

.sim-overlay-state { font-weight: bold; }
.sim-overlay-state-explore { color: yellow; }
.sim-overlay-state-engage { color: red; }
.sim-overlay-state-reorg { color: green; }

.sim-overlay-throwable-true { color: red; }
.sim-overlay-throwable-false { color: gray; }

.sim-overlay-controls {
}

.sim-overlay-controls>button {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 1px 0px;
  padding: 0.1em 0.2em;
}
.sim-overlay-controls>button:disabled { color: gray; }

.sim-overlay-stat-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.sim-overlay-stat-row-name {
  width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sim-overlay-stat-row-kills { margin-left: 0em 1em; }
.sim-overlay-stat-row-dealt {
  flex-grow: 1;
  text-align: right;
  margin-right: 1em;
}

.sim-overlay-embed {
  @apply sim-overlay;
  width: 100%;
  left: 0;
}

.sim-overlay-entity {
  display: flex;
  height: 72.25px;
  max-width: 414px;

  padding: inherit;

  align-items: center;
}

.sim-overlay-entity-img {
  position: relative;
  margin:0px 10px 0px  0px;

  height: var(--sim-overlay-entity-img-width-height);
  width: var(--sim-overlay-entity-img-width-height);
}

.sim-overlay-entity-img-icon.list-portrait {
  height: var(--sim-overlay-entity-img-width-height);
  width: var(--width);
}

.sim-overlay-entity-img-icon {
  position: absolute;
  z-index: 2;

}

.sim-overlay-entity-img-background {
  position:absolute;
  height:100%;
  top:0px;
  z-index:0;
}

.sim-overlay-entity-img-hover {
  position:absolute;
  width: var(--sim-overlay-entity-img-hover-width);
  height:var(--sim-overlay-entity-img-width-height);
  top:0px;
  right:0px;
  z-index:1;
}

.sim-overlay-entity-img-hover > img {
  height:100%;
}

.sim-overlay-entity-detail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sim-overlay-entity-action-indicator {
  width: var(--sim-overlay-entity-action-indicator-width);
  height: var(--sim-overlay-entity-action-indicator-height);

  margin-left: auto;
}

.sim-overlay-entity-action-indicator > img[title] {
  background-color:inherit;
}

.entityname-0 {
  color: rgb(45, 240, 130);
}

.entityname-1 {
  color: red;
}

.entityname-2 {
  color: yellow;
}

.entityname-over {
  background-color: black;
}

.entity-over {
  /* background-color: gray; */
}

.entity-dead {
  color: grey;
}


.side-select-root {
  display: flex;
  align-items: center;
}

.side-select {
  position: relative;
  text-align: center;
  user-select: none;

  --side-select-width: 100px;
  width: var(--side-select-width);
}

.side-select > *:not(:first-child) {
  top: 0;
  position: absolute;
}

.side-btn {
  width: var(--side-select-width);
  border: 1px solid black;
  background: #999;
  padding: 2px 8px;
}

.side-btn:hover {
  background: #aaa;
}

.side-btn-selected {
  background: #bbb;
}

.side-btn-selected:hover {
  background: #ddd;
}

.side-btn-1 {
  left: calc(-1 * var(--side-select-width));
}
.side-btn-2 {
  left: calc(-2 * var(--side-select-width));
}
.side-btn-3 {
  left: calc(-3 * var(--side-select-width));
}
.side-btn-4 {
  left: calc(-4 * var(--side-select-width));
}
.side-btn-5 {
  left: calc(-5 * var(--side-select-width));
}

.sim-overlay-info-entities {
}

.sim-overlay-info-entities > div {
  vertical-align: top;
  display: inline-block;
  max-width: 400px;
}
