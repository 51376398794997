.thebluebutton-SI {
  height: 50px;
  width: 464px;
}

.thebluebutton-SI .content {
  height: 50px;
}

.thebluebutton-SI .overlap-group-8 {
  height: 53px;
  position: relative;
  width: 463px;
}

.thebluebutton-SI .vector-green {
  height: 53px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 462px;
}

.thebluebutton-SI .pt-label-wrapper {
  align-items: center;
  display: flex;

  height: 50px;
  justify-content: center;
  left: 1px;
  position: absolute;
  top: 0;
  width: 462px;
}

.thebluebutton-SI .pt-label {
  color: #d6fbf9;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 19px;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.thebluebutton-SI .text-wrapper-4 {
  color: #00000003;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 46px;
  left: 2px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 1px;
  width: 460px;
}

.thebluebutton-SI.disabled {
  opacity: 0.45;
}
.thebluebutton-SI .pt-label-wrapper>* { margin-left: 23px; } .thebluebutton-SI .pt-label-wrapper>*:first-child { margin-left: 0; }
