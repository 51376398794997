.CIPO-cell {
  width: 63px;
}

.CIPO-cell .mercenary {
  align-items: center;
  display: flex;
  flex-direction: column;

  padding: 2px;
  position: relative;
}

.CIPO-cell .profile {
  height: 64px;
  position: relative;
  width: 59px;
  background-color: var(--c-fh1-flexoverlay-portrait-bg);
  border-radius: 5px;
}

.CIPO-cell .overlap {
  height: 100px;
  position: relative;
}

.CIPO-cell .firearm-type {
  align-items: center;
  background-color: #e1a200;
  display: inline-flex;

  justify-content: center;
  left: 2.5px;
  padding: 1px 4px;
  position: absolute;
  top: 49px;
  border-bottom-left-radius: 2px;
  z-index: 1;  
}

.CIPO-cell .pt-firearm-ty {
  color: #000000;
  font-family: "Pretendard", Helvetica;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CIPO-cell .training {
  align-items: center;
  background-color: #e1a200;
  display: inline-flex;

  justify-content: center;
  left: 0;
  padding: 0px 0px;
  position: absolute;
  top: 107px;
}

.CIPO-cell .pt-training {
  color: #000000;
  font-family: "Pretendard", Helvetica;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 15px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CIPO-cell .perk-enable {
  align-items: center;
  background-color: green;
  display: inline-flex;

  justify-content: center;
  left: 0;
  padding: 0px 0px;
  position: absolute;
  top: 0;
  z-index: 2;
}

.CIPO-cell .pt-perk-enable {
  color: #000000;
  font-family: "Pretendard", Helvetica;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CIPO-cell .pt-agent-name {
  color: var(--c-bd-content-box-fg);
  font-size: 10px;
  font-weight: 700;
  height: 13px;
  letter-spacing: 0;
  line-height: 12px;
  position: relative;
  white-space: nowrap;
  width: 57px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CIPO-cell .HP {
  align-items:
    /* PMC */
    flex-start;
  align-content: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.CIPO-cell .stamina {
  align-items:
    /* PMC */
    flex-start;
  align-content: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.CIPO-cell .pt-agent-hp-val {
  color: #34a100;
  font-family: "Pretendard", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CIPO-cell .pt-agent-hp-cap {
  color: #6f6f6f;
  font-family: "Pretendard", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CIPO-cell .pt-agent-stamina-val {
  font-family: "Pretendard", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CIPO-cell .pt-agent-stamina-val-0 {
  color: #b93b00;
}

.CIPO-cell .pt-agent-stamina-val-1 {
  color: #bb7900;
}

.CIPO-cell .pt-agent-stamina-val-2 {
  color: #b3b300;
}

.CIPO-cell .pt-agent-stamina-val-3 {
  color: #67ac00;
}

.CIPO-cell .pt-agent-stamina-val-4 {
  color: #38a700;
}

.CIPO-cell .pt-agent-stamina-cap {
  color: #6f6f6f;
  font-family: "Pretendard", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.CIPO-cell .swap-dummy-instance {
  height: 64px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 59px !important;
}

.CIPO-cell .design-component-instance-node {
  height: 60px !important;
  width: 55px !important;
}

.CIPO-cell .swap-dummy-2 {
  height: 55px !important;
  white-space: unset !important;
  width: 45px !important;
}

.CIPO-cell .mercenary>* {
  margin-top: 2px;
}

.CIPO-cell .mercenary>*:first-child {
  margin-top: 0;
}

.CIPO-cell .firearm-type>* {
  margin-left: 10px;
}

.CIPO-cell .firearm-type>*:first-child {
  margin-left: 0;
}