
.title-root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("/public/img/title/SLB_Title_BGI_demo_novid.png");
  background-size: cover;
  background-position: center;
}

.title-button {
  position: absolute;
  bottom: 15vh;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.title-button-start {
  width: 93px;
  height: 34px;
}

.title-button-exit {
  width: 64px;
  height: 34px;
}
