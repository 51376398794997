.icon-equipment {
  left: 0;
  position: absolute;
  top: 0;
}

.icon-equipment.bullet-proof {
  height: 31px;
  width: 29px;
}

.icon-equipment.grenade-1 {
  height: 31px;
  width: 30px;
}

.icon-equipment.locked {
  height: 26px;
  width: 27px;
}

.icon-equipment.grenade-2 {
  height: 30px;
  width: 30px;
}

.icon-equipment.firearm {
  height: 29px;
  width: 33px;
}
