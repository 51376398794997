.perk-grid {
  gap: 3px;
  margin: 3px;
  padding: 3px;
}

.perk-grid>div {
  text-align: center;
  padding: 3px 0;
  cursor: default;
}

.perk-grid>.locked,
.perk-grid>.unlocked,
.perk-grid>.completed {
  color: var(--c-yd-grid-item-fg);
  border: 1px solid var(--c-yd-grid-item-border);
}

.perk-grid>.locked {
  background-color: var(--c-yd-grid-item-locked-bg);
}

.perk-grid>.unlocked {
  background-color: var(--c-yd-grid-item-locked-fg);
  cursor: pointer;
}

.perk-grid>.unlocked:hover {

}

.perk-grid>.empty {
  background-color: var(--c-yd-grid-item-empty-bg);
  color: var(--c-yd-grid-item-empty-fg);
  border: none;
}

.perk-grid>.completed {
  background-color: var(--c-yd-grid-item-completed-bg);
  color: var(--c-yd-grid-item-completed-fg);
  border: var(--c-yd-grid-item-completed-border);
}

.perk-grid>.perkgroup {
  border: 1px solid var(--c-yd-grid-header-border);
}